/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ProfileSyncIcon = createIconsComponent(_jsxs("svg", {
  width: 40,
  height: 40,
  viewBox: "0 0 40 40",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M27.261 28.0458C27.261 23.3238 24.0132 19.4935 20.0206 19.4935C16.028 19.4935 12.7802 23.3238 12.7802 28.0458C12.7802 28.3701 12.987 28.6133 13.256 28.6133H26.7646C27.0335 28.6133 27.2404 28.3498 27.2404 28.0458H27.261Z",
    fill: "none",
    fillOpacity: 0.48
  }), _jsx("path", {
    d: "M23.31 10.6979C25.1305 12.4813 25.1305 15.3591 23.31 17.1425C21.4896 18.926 18.552 18.926 16.7316 17.1425C14.9111 15.3591 14.9111 12.4813 16.7316 10.6979C18.552 8.9145 21.4896 8.9145 23.31 10.6979Z",
    fill: "none",
    fillOpacity: 0.48
  }), _jsx("path", {
    d: "M3.1815 17.8315L9.55309 13.9201C10.0289 13.6161 9.96683 12.9271 9.44965 12.7244L7.31889 11.8935C9.98751 7.25256 14.7662 4.27344 20.0207 4.27344C27.2818 4.27344 33.4466 9.76556 34.6878 17.3248C34.8533 18.3179 35.7221 19.0272 36.7358 19.0272C36.8392 19.0272 36.9633 19.0272 37.0668 19.0069C38.1839 18.8245 38.97 17.7909 38.7838 16.6763C37.1909 7.13096 29.3092 0.199951 20.0207 0.199951C13.0492 0.199951 6.73966 4.17211 3.40905 10.3735L1.15417 9.5021C0.636999 9.29944 0.0784506 9.76556 0.22326 10.2925L2.12646 17.4262C2.25058 17.872 2.76776 18.0747 3.16081 17.8315H3.1815Z",
    fill: "none",
    fillOpacity: 0.24
  }), _jsx("path", {
    d: "M37.8738 22.5742C37.7496 22.1283 37.2325 21.9257 36.8394 22.1689L30.4678 26.0802C29.992 26.3842 30.0541 27.0733 30.5712 27.2759L32.702 28.1069C30.0334 32.7478 25.2547 35.7472 20.0002 35.7472C12.7391 35.7472 6.57435 30.2551 5.33313 22.6958C5.14695 21.6014 4.09191 20.8313 2.95413 21.0137C1.83703 21.1961 1.05093 22.2297 1.23711 23.3443C2.80932 32.8897 10.6911 39.8004 19.9795 39.8004C26.951 39.8004 33.2606 35.8282 36.5912 29.6268L38.846 30.4983C39.3632 30.7009 39.9218 30.2348 39.777 29.7079L37.8738 22.5742Z",
    fill: "none",
    fillOpacity: 0.24
  })]
}), 'ProfileSyncIcon', 'Navigation');
export default ProfileSyncIcon;