/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HealthcareIcon = createIconsComponent(_jsxs("svg", {
  width: 18,
  height: 20,
  viewBox: "0 0 18 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.62 3.38994V2.64994C5.62 2.45994 5.69 2.27994 5.83 2.14994C5.96 2.01994 6.14 1.93994 6.33 1.93994H11.66C11.85 1.93994 12.03 2.01994 12.16 2.14994C12.29 2.27994 12.37 2.45994 12.37 2.64994V3.38994C12.82 3.40994 13.24 3.43994 13.62 3.45994V2.63994C13.62 2.11994 13.41 1.61994 13.05 1.25994C12.68 0.899941 12.18 0.689941 11.66 0.689941H6.33C5.81 0.689941 5.31 0.899941 4.94 1.25994C4.57 1.62994 4.37 2.11994 4.37 2.63994V3.45994C4.75 3.42994 5.17 3.40994 5.62 3.38994Z",
    fill: "none",
    fillOpacity: 0.24
  }), _jsx("path", {
    d: "M17.75 17.1301C17.87 15.9601 18 14.1301 18 11.8101C18 9.49006 17.87 7.66006 17.75 6.49006C17.66 5.60006 17.03 4.89006 16.13 4.74006C14.85 4.54006 12.62 4.31006 9 4.31006C5.38 4.31006 3.15 4.54006 1.87 4.74006C0.96 4.89006 0.34 5.60006 0.25 6.49006C0.13 7.66006 0 9.49006 0 11.8101C0 14.1301 0.13 15.9601 0.25 17.1301C0.34 18.0201 0.97 18.7301 1.87 18.8801C3.15 19.0801 5.38 19.3101 9 19.3101C12.62 19.3101 14.85 19.0801 16.13 18.8801C17.04 18.7301 17.66 18.0201 17.75 17.1301ZM9.96 15.9201C9.72 15.9501 9.4 15.9801 9 15.9801C8.6 15.9801 8.28 15.9501 8.04 15.9201C7.65 15.8701 7.4 15.5301 7.38 15.1401C7.37 14.7601 7.35 14.2101 7.34 13.4701C6.6 13.4601 6.05 13.4401 5.67 13.4301C5.27 13.4201 4.94 13.1601 4.89 12.7701C4.86 12.5301 4.83 12.2101 4.83 11.8101C4.83 11.4101 4.86 11.0901 4.89 10.8501C4.94 10.4601 5.28 10.2101 5.67 10.1901C6.05 10.1801 6.6 10.1601 7.34 10.1501C7.34 9.41006 7.37 8.86006 7.38 8.48006C7.39 8.08006 7.65 7.75006 8.04 7.70006C8.28 7.67006 8.6 7.64006 9 7.64006C9.4 7.64006 9.72 7.67006 9.96 7.70006C10.35 7.75006 10.6 8.09006 10.62 8.48006C10.63 8.86006 10.65 9.41006 10.66 10.1501C11.4 10.1501 11.95 10.1801 12.33 10.1901C12.73 10.2001 13.06 10.4601 13.11 10.8501C13.14 11.0901 13.17 11.4101 13.17 11.8101C13.17 12.2101 13.14 12.5301 13.11 12.7701C13.06 13.1601 12.72 13.4101 12.33 13.4301C11.95 13.4401 11.4 13.4601 10.66 13.4701C10.65 14.2101 10.63 14.7601 10.62 15.1401C10.61 15.5401 10.35 15.8701 9.96 15.9201Z",
    fill: "none",
    fillOpacity: 0.48
  })]
}), 'HealthcareIcon', 'Navigation');
export default HealthcareIcon;