import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import useMatchMedia from '@segment/utils/useMatchMedia';

var useUtilityNavDescriptions = function useUtilityNavDescriptions(descriptions) {
  var theme = useTheme();
  var keys = useMemo(function () {
    if (!descriptions) return [];
    return Object.keys(descriptions);
  }, [descriptions]);
  var breakpointsKeys = Object.keys(theme.breakpoints.values);
  var sortedArrayOfKeys = useMemo(function () {
    return breakpointsKeys.filter(function (main) {
      return keys.some(function (key) {
        return key === main;
      });
    });
  }, [breakpointsKeys, keys]);

  var getNextKey = function getNextKey(key, index) {
    if (sortedArrayOfKeys[index - 1]) {
      var getIndexofCurrentKey = breakpointsKeys.indexOf(key);

      if (breakpointsKeys[getIndexofCurrentKey - 1] !== sortedArrayOfKeys[index - 1]) {
        return breakpointsKeys[breakpointsKeys.indexOf(key) - 1];
      }
    }

    return false;
  };

  var array = sortedArrayOfKeys.map(function (key, index) {
    var nextKey = getNextKey(key, index);
    var minWidth = sortedArrayOfKeys[index - 1] ? " and ".concat(theme.breakpoints.up(nextKey ? nextKey : key, false)) : "";
    var mediaString = "".concat(theme.breakpoints.down(key, false)).concat(minWidth);
    return useMatchMedia(mediaString, true);
  });
  return descriptions[sortedArrayOfKeys[array.indexOf(true)]];
};

export default useUtilityNavDescriptions;