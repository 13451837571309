/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ContactIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.9925 12.9668V9.85919C12.9925 8.44144 11.8432 7.29211 10.4254 7.29211H4.57405V4.31971C4.57405 3.1258 5.54189 2.15796 6.7358 2.15796H16.824C18.0179 2.15796 18.9858 3.1258 18.9858 4.31971V10.805L19 14.811C19.0008 15.0473 18.81 15.2395 18.5737 15.2403C18.4597 15.2407 18.3502 15.1956 18.2696 15.115L16.1214 12.9668H12.9926H12.9925Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M0.999954 14.9933V10.7148C0.999954 9.76963 1.76617 9.00342 2.71134 9.00342H9.55688C10.5021 9.00342 11.2683 9.76963 11.2683 10.7148V14.9933C11.2683 15.9384 10.5021 16.7047 9.55688 16.7047H2.81036L1.74602 17.7234C1.57531 17.8868 1.30448 17.8808 1.14109 17.7101C1.06488 17.6305 1.02233 17.5245 1.02233 17.4143V15.2707C1.00761 15.1804 0.999956 15.0877 0.999956 14.9933H0.999954ZM4.86327 11.5705C4.62698 11.5705 4.43542 11.7621 4.43542 11.9983C4.43542 12.2347 4.62698 12.4262 4.86327 12.4262H9.14173C9.37803 12.4262 9.56958 12.2347 9.56958 11.9983C9.56958 11.7621 9.37803 11.5705 9.14173 11.5705H4.86327ZM7.43035 13.2819C7.19406 13.2819 7.0025 13.4734 7.0025 13.7097C7.0025 13.946 7.19406 14.1376 7.43035 14.1376H9.14173C9.37803 14.1376 9.56958 13.946 9.56958 13.7097C9.56958 13.4734 9.37803 13.2819 9.14173 13.2819H7.43035Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.86327 11.5706C4.62698 11.5706 4.43542 11.7621 4.43542 11.9984C4.43542 12.2347 4.62698 12.4262 4.86327 12.4262H9.14173C9.37803 12.4262 9.56958 12.2347 9.56958 11.9984C9.56958 11.7621 9.37803 11.5706 9.14173 11.5706H4.86327ZM7.43035 13.2819C7.19406 13.2819 7.0025 13.4734 7.0025 13.7097C7.0025 13.946 7.19406 14.1376 7.43035 14.1376H9.14173C9.37803 14.1376 9.56958 13.946 9.56958 13.7097C9.56958 13.4734 9.37803 13.2819 9.14173 13.2819H7.43035Z",
    fill: "white"
  })]
}), 'ContactIcon', 'Navigation');
export default ContactIcon;