/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MobileIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2.55563 1H13.4444C14.2998 1 15 1.67455 15 2.50007V5.5H13.4444C11.7284 5.5 10.3333 6.84527 10.3333 8.49992V19H2.55563C1.70023 19 1 18.3248 1 17.5V2.50007C1 1.67455 1.70023 1 2.55563 1ZM6.44437 17.5H8C8.43003 17.5 8.7777 17.1647 8.7777 16.75C8.7777 16.3354 8.43003 16.0001 8 16.0001H6.44437C6.01457 16.0001 5.66667 16.3354 5.66667 16.75C5.66667 17.1647 6.01457 17.5 6.44437 17.5Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.3999 7H17.5999C18.3698 7 19 7.67523 19 8.49986V17.5C19 18.3248 18.3698 19 17.5999 19H13.3999C12.6302 19 12 18.3248 12 17.5V8.49986C12 7.67523 12.6302 7 13.3999 7ZM14.8 16.75C14.8 17.1647 15.1131 17.5 15.4999 17.5C15.8869 17.5 16.2 17.1647 16.2 16.75C16.2 16.3353 15.8869 16.0001 15.4999 16.0001C15.1131 16.0001 14.8 16.3353 14.8 16.75Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.00002 17.4999H6.44439C6.01459 17.4999 5.66669 17.1646 5.66669 16.7499C5.66669 16.3353 6.01459 16 6.44439 16H8.00002C8.43005 16 8.77772 16.3353 8.77772 16.7499C8.77772 17.1646 8.43005 17.4999 8.00002 17.4999Z",
    fill: "white"
  }), _jsx("path", {
    d: "M15.4999 17.4999C15.1131 17.4999 14.8 17.1646 14.8 16.7499C14.8 16.3352 15.1131 16 15.4999 16C15.8869 16 16.2 16.3352 16.2 16.7499C16.2 17.1646 15.8869 17.4999 15.4999 17.4999Z",
    fill: "white"
  })]
}), 'MobileIcon', 'Navigation');
export default MobileIcon;