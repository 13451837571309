/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ReverseEtlIcon = createIconsComponent(_jsxs("svg", {
  width: 40,
  height: 36,
  viewBox: "0 0 40 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M35.3264 22.7182C34.7266 22.7194 34.1331 22.847 33.5813 23.0932C33.0295 23.3395 32.5307 23.6994 32.1144 24.1516L27.2024 21.5752C27.2448 21.334 27.2688 21.0897 27.274 20.8445C27.2688 20.5993 27.2448 20.3549 27.2024 20.1137L32.1144 17.5374C32.8371 18.318 33.7967 18.8111 34.8286 18.9321C35.8605 19.0531 36.9005 18.7944 37.7702 18.2005C38.64 17.6065 39.2853 16.7143 39.5956 15.6767C39.9059 14.6391 39.8618 13.5208 39.4709 12.5135C39.08 11.5062 38.3667 10.6727 37.4531 10.1558C36.5396 9.63893 35.4828 9.47089 34.464 9.6805C33.4451 9.89012 32.5276 10.4643 31.8687 11.3047C31.2099 12.1451 30.8507 13.1992 30.8528 14.2865C30.8581 14.5317 30.882 14.776 30.9244 15.0172L26.0124 17.5936C25.3935 16.9213 24.5969 16.458 23.7247 16.2632C22.8525 16.0685 21.9446 16.1511 21.1176 16.5005C20.2906 16.8499 19.5822 17.4502 19.0834 18.2242C18.5845 18.9982 18.318 19.9107 18.318 20.8445C18.318 21.7783 18.5845 22.6908 19.0834 23.4648C19.5822 24.2388 20.2906 24.8391 21.1176 25.1885C21.9446 25.5379 22.8525 25.6205 23.7247 25.4257C24.5969 25.231 25.3935 24.7677 26.0124 24.0954L30.9244 26.6717C30.882 26.9129 30.8581 27.1573 30.8528 27.4025C30.8528 28.3289 31.1152 29.2346 31.6068 30.0049C32.0983 30.7753 32.797 31.3756 33.6145 31.7302C34.4319 32.0847 35.3314 32.1775 36.1992 31.9968C37.067 31.816 37.8641 31.3699 38.4897 30.7148C39.1154 30.0597 39.5414 29.225 39.714 28.3163C39.8867 27.4077 39.7981 26.4658 39.4595 25.6099C39.1209 24.7539 38.5475 24.0224 37.8118 23.5076C37.0761 22.9929 36.2112 22.7182 35.3264 22.7182Z",
    fill: "none"
  }), _jsxs("g", {
    opacity: 0.48,
    children: [_jsx("path", {
      d: "M12.9496 13.516C19.9911 13.516 25.6993 10.5799 25.6993 6.95799C25.6993 3.33611 19.9911 0.399994 12.9496 0.399994C5.90818 0.399994 0.199951 3.33611 0.199951 6.95799C0.199951 10.5799 5.90818 13.516 12.9496 13.516Z",
      fill: "none"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M20.3595 28.6364C18.0946 29.4132 15.4926 29.812 12.9496 29.812C8.25239 29.812 3.3404 28.4513 0.750201 25.8609C0.695591 25.8203 0.63292 25.7989 0.569021 25.7989C0.505123 25.7989 0.442451 25.8203 0.387842 25.8609C0.333297 25.8954 0.287197 25.947 0.254205 26.0103C0.221212 26.0737 0.202496 26.1465 0.199951 26.2215V29.0415C0.199951 32.6648 5.90376 35.5995 12.9496 35.5995C19.9955 35.5995 25.6993 32.6648 25.6993 29.0415V28.4555C25.288 28.6278 24.8616 28.764 24.4244 28.8617C23.0711 29.1639 21.6688 29.0845 20.3595 28.6364Z",
      fill: "none"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M15.1031 22.3898C14.3865 22.4526 13.6657 22.4842 12.9496 22.4842C8.25239 22.4842 3.3404 21.1234 0.750201 18.533C0.695591 18.4925 0.63292 18.471 0.569021 18.471C0.505123 18.471 0.442451 18.4925 0.387842 18.533C0.333297 18.5676 0.287197 18.6192 0.254205 18.6825C0.221212 18.7458 0.202496 18.8187 0.199951 18.8937V21.7136C0.199951 25.3369 5.90376 28.2716 12.9496 28.2716C14.9058 28.2716 16.7584 28.0454 18.4143 27.6411C17.5855 27.0558 16.867 26.3066 16.3021 25.4301C15.7075 24.5075 15.3015 23.4725 15.1031 22.3898Z",
      fill: "none"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M12.9496 15.1547C14.5088 15.1547 16.0902 15.0048 17.6024 14.7061C17.1109 15.1618 16.6734 15.6828 16.3021 16.2587C15.4349 17.6043 14.9689 19.189 14.9628 20.8118C14.3071 20.8652 13.6347 20.893 12.9496 20.893C5.90376 20.893 0.199951 17.9583 0.199951 14.335V11.4659C0.214371 11.4084 0.238565 11.3554 0.270945 11.3102C0.303325 11.265 0.343161 11.2286 0.387842 11.2035C0.442451 11.163 0.505123 11.1416 0.569021 11.1416C0.63292 11.1416 0.695591 11.163 0.750201 11.2035C3.3404 13.7939 8.25239 15.1547 12.9496 15.1547Z",
      fill: "none"
    })]
  })]
}), 'ReverseEtlIcon', 'Navigation');
export default ReverseEtlIcon;