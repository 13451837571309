/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CatalogIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.1009 14.6551C14.2867 13.4693 12.1672 12.8356 9.9997 12.8309C7.83222 12.8356 5.71273 13.4694 3.89853 14.6551C3.74992 14.7551 3.54883 14.7198 3.44321 14.5752C1.70066 12.0838 1.52234 8.81993 2.98313 6.15367C4.44391 3.4874 7.29094 1.88028 10.329 2.00698C14.5677 2.21817 17.9192 5.67413 17.9995 9.91633C18.0184 11.5821 17.5135 13.2117 16.5562 14.5752C16.4506 14.7198 16.2495 14.7551 16.1009 14.6551ZM9.99969 3.8332C9.72356 3.8332 9.49971 4.057 9.49971 4.33308V5.33283C9.49971 5.6089 9.72356 5.8327 9.99969 5.8327C10.2758 5.8327 10.4997 5.6089 10.4997 5.33283V4.33308C10.4997 4.057 10.2758 3.8332 9.99969 3.8332ZM5.33316 10.4982H4.33319C4.05705 10.4982 3.8332 10.2744 3.8332 9.99831C3.8332 9.72224 4.05705 9.49844 4.33319 9.49844H5.33316C5.6093 9.49844 5.83315 9.72224 5.83315 9.99831C5.83315 10.2744 5.6093 10.4982 5.33316 10.4982ZM6.69979 7.19917C6.83239 7.19917 6.95954 7.14636 7.05311 7.05242C7.14694 6.95867 7.19967 6.83148 7.19967 6.69885C7.19967 6.56622 7.14694 6.43902 7.05311 6.34527L6.34646 5.63944C6.15037 5.44991 5.83852 5.45247 5.64557 5.6452C5.45261 5.83793 5.44976 6.1497 5.63915 6.34593L6.34646 7.05242C6.44004 7.14636 6.56718 7.19917 6.69979 7.19917ZM9.94569 11.6286C9.3022 11.636 8.71875 11.2518 8.4714 10.6579C8.22406 10.0639 8.36243 9.37928 8.82105 8.92794C9.32504 8.42474 13.5156 5.84205 13.9916 5.54879C14.123 5.46777 14.293 5.48765 14.4022 5.59683C14.5114 5.70601 14.5313 5.8759 14.4502 6.00734C14.1569 6.48322 11.5736 10.6728 11.0697 11.176C10.7698 11.4699 10.3655 11.6326 9.94569 11.6286ZM14.1662 9.99831C14.1662 10.2744 14.3901 10.4982 14.6662 10.4982H15.6662C15.9423 10.4982 16.1662 10.2744 16.1662 9.99831C16.1662 9.72224 15.9423 9.49844 15.6662 9.49844H14.6662C14.3901 9.49844 14.1662 9.72224 14.1662 9.99831Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.99969 13.8306C8.10615 13.8263 6.25003 14.3577 4.64584 15.3635C4.5587 15.4197 4.50208 15.5127 4.4922 15.6159C4.48233 15.7191 4.52027 15.8211 4.59518 15.8927C7.65024 18.7023 12.3492 18.7023 15.4042 15.8927C15.4791 15.8212 15.5171 15.7193 15.5072 15.6162C15.4973 15.5131 15.4407 15.4202 15.3535 15.3642C13.7495 14.358 11.8933 13.8263 9.99969 13.8306Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9.99969 3.83325C9.72356 3.83325 9.49971 4.05705 9.49971 4.33313V5.33288C9.49971 5.60895 9.72356 5.83275 9.99969 5.83275C10.2758 5.83275 10.4997 5.60895 10.4997 5.33288V4.33313C10.4997 4.05705 10.2758 3.83325 9.99969 3.83325ZM5.33317 10.4982H4.33319C4.05706 10.4982 3.83321 10.2744 3.83321 9.99836C3.83321 9.72229 4.05706 9.49848 4.33319 9.49848H5.33317C5.6093 9.49848 5.83315 9.72229 5.83315 9.99836C5.83315 10.2744 5.6093 10.4982 5.33317 10.4982ZM6.69979 7.19922C6.83239 7.19922 6.95954 7.14641 7.05311 7.05247C7.14695 6.95872 7.19967 6.83153 7.19967 6.6989C7.19967 6.56626 7.14695 6.43907 7.05311 6.34532L6.34647 5.63949C6.15037 5.44996 5.83852 5.45252 5.64557 5.64525C5.45262 5.83798 5.44976 6.14975 5.63915 6.34598L6.34647 7.05247C6.44004 7.14641 6.56718 7.19922 6.69979 7.19922ZM9.94569 11.6287C9.30221 11.6361 8.71875 11.2519 8.47141 10.6579C8.22406 10.064 8.36244 9.37933 8.82105 8.92799C9.32504 8.42479 13.5156 5.8421 13.9916 5.54884C14.1231 5.46782 14.293 5.4877 14.4022 5.59688C14.5114 5.70606 14.5313 5.87595 14.4502 6.00739C14.1569 6.48327 11.5736 10.6729 11.0697 11.1761C10.7698 11.4699 10.3655 11.6327 9.94569 11.6287ZM14.1662 9.99836C14.1662 10.2744 14.3901 10.4982 14.6662 10.4982H15.6662C15.9423 10.4982 16.1662 10.2744 16.1662 9.99836C16.1662 9.72229 15.9423 9.49848 15.6662 9.49848H14.6662C14.3901 9.49848 14.1662 9.72229 14.1662 9.99836Z",
    fill: "white"
  })]
}), 'CatalogIcon', 'Navigation');
export default CatalogIcon;