/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApiStatusIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M18.25 17.5H2.875C2.66789 17.5 2.5 17.3321 2.5 17.125V1.75C2.5 1.33579 2.16421 1 1.75 1C1.33579 1 1 1.33579 1 1.75V17.8915H1C1.00082 18.5036 1.49708 18.9996 2.10923 19H18.25C18.6642 19 19 18.6642 19 18.25C19 17.8358 18.6642 17.5 18.25 17.5Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M5.125 15.9999H16.75C17.3448 16.024 17.8474 15.5633 17.875 14.9686V13.6561C17.8474 13.0615 17.3448 12.6007 16.75 12.6249H5.125C4.5302 12.6007 4.02756 13.0615 4 13.6561V14.9686C4.02756 15.5633 4.5302 16.024 5.125 15.9999Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M4 9.71868C4.02756 10.3133 4.5302 10.7741 5.125 10.7499H12.25C12.8448 10.7741 13.3474 10.3133 13.375 9.71868V8.40618C13.3474 7.81153 12.8448 7.35078 12.25 7.37493H5.125C4.5302 7.35078 4.02756 7.81153 4 8.40618V9.71868Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M5.125 5.49993H8.5C9.0948 5.52408 9.59744 5.06333 9.625 4.46868V3.15618C9.59744 2.56153 9.0948 2.10078 8.5 2.12493H5.125C4.5302 2.10078 4.02756 2.56153 4 3.15618V4.46868C4.02756 5.06333 4.5302 5.52408 5.125 5.49993Z",
    fill: "none"
  })]
}), 'ApiStatusIcon', 'Navigation');
export default ApiStatusIcon;