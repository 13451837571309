/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var PrivacyIcon = createIconsComponent(_jsx("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.2855 2H13.6667C13.9888 2 14.25 2.26611 14.25 2.59437V12.4015C14.25 12.7298 13.9888 12.9959 13.6667 12.9959H2.58333C2.42835 12.9959 2.27975 12.933 2.17033 12.8212C2.061 12.7095 1.99972 12.5581 2 12.4003L2.014 4.32757C2.01525 3.04772 3.02945 2.00849 4.2855 2ZM2.74103 16.5622H13.6669C13.989 16.5622 14.2502 16.8283 14.2502 17.1565V29.6693C14.2502 29.7017 14.245 29.7339 14.2349 29.7646C14.1833 29.9201 14.0178 30.0036 13.8652 29.951C8.12183 27.8903 3.81591 22.9739 2.45753 16.9259C2.45266 16.9043 2.45018 16.8823 2.45012 16.8601C2.44971 16.696 2.57995 16.5626 2.74103 16.5622ZM29.2487 16.5622H18.3333C18.0112 16.5622 17.75 16.8283 17.75 17.1565V29.7026C17.7499 29.8667 17.8804 29.9999 18.0415 30C18.0733 30 18.1049 29.9947 18.135 29.9843C23.8836 27.9155 28.1876 22.9847 29.5333 16.9259C29.5694 16.7659 29.4714 16.6065 29.3144 16.5697C29.293 16.5647 29.271 16.5621 29.249 16.5622H29.2487ZM27.7145 2C28.9706 2.00851 29.9848 3.04771 29.986 4.32757L30 12.4003C30.0003 12.5581 29.939 12.7095 29.8297 12.8212C29.7202 12.933 29.5716 12.9959 29.4167 12.9959H18.3333C18.0112 12.9959 17.75 12.7298 17.75 12.4015V2.59437C17.75 2.26611 18.0112 2 18.3333 2H27.7145Z",
    fill: "#6CC9EC"
  })
}), 'PrivacyIcon', 'Segment');
export default PrivacyIcon;