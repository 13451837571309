/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SourcesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M1.86627 6.55L9.10302 9.7C9.67531 9.94799 10.3247 9.94799 10.897 9.7L18.1337 6.55C18.7035 6.30216 18.9645 5.63938 18.7166 5.06963C18.6031 4.80859 18.3948 4.6003 18.1337 4.48675L10.9 1.339C10.3277 1.0911 9.67833 1.0911 9.10602 1.339L1.86777 4.4845C1.29781 4.73186 1.0363 5.39443 1.28365 5.96439C1.39724 6.22612 1.60604 6.43491 1.86777 6.5485L1.86627 6.55Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M18.133 8.98604L17.2 8.57954C17.0086 8.4966 16.7914 8.4966 16.6 8.57954L9.99998 11.4498L3.39998 8.57954C3.20858 8.4966 2.99138 8.4966 2.79998 8.57954L1.86698 8.98529C1.29702 9.23265 1.0355 9.89522 1.28286 10.4652C1.39645 10.7269 1.60524 10.9357 1.86698 11.0493L9.09998 14.1948C9.67227 14.4428 10.3217 14.4428 10.894 14.1948L18.1307 11.05C18.7007 10.8027 18.9622 10.1401 18.7149 9.57015C18.6013 9.30842 18.3925 9.09963 18.1307 8.98604H18.133Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M18.133 13.45L17.2 13.0457C17.0087 12.9623 16.7913 12.9623 16.6 13.0457L9.99998 15.916L3.39998 13.0457C3.20869 12.9623 2.99127 12.9623 2.79998 13.0457L1.86698 13.4507C1.29702 13.6981 1.0355 14.3607 1.28286 14.9306C1.39645 15.1924 1.60524 15.4011 1.86698 15.5147L9.09998 18.661C9.67227 18.909 10.3217 18.909 10.894 18.661L18.1277 15.5162C18.6979 15.2694 18.96 14.607 18.7131 14.0368C18.5995 13.7744 18.3902 13.5651 18.1277 13.4515L18.133 13.45Z",
    fill: "none"
  })]
}), 'SourcesIcon', 'Navigation');
export default SourcesIcon;