/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var IosIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M14 0C14 2.76114 11.7605 4.99836 9 5C9 2.23722 11.2386 0 14 0Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M13.7074 10.875C13.7074 9.46732 14.6923 8.27088 16.0684 7.81957C15.1969 6.70525 13.9317 6 12.5179 6C11.6025 6 10.6426 6.546 10.6426 6.546C10.3652 6.70395 9.91138 6.70395 9.63355 6.546C9.63355 6.546 8.67384 6 7.75844 6C5.13068 6 2.99916 8.42472 3 11.4167C3 15.6018 4.86335 19 7.16371 19C8.26496 19 9.61444 18.3426 9.61444 18.3426C9.90298 18.2018 10.3746 18.2018 10.6624 18.3426C10.6624 18.3426 12.0112 19 13.1124 19C14.8905 19 16.4025 16.9672 17 14.1122C15.1581 13.984 13.7074 12.5847 13.7074 10.875Z",
    fill: "none"
  })]
}), 'IosIcon', 'Navigation');
export default IosIcon;