/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var JourneysIcon = createIconsComponent(_jsx("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M1.333 5a3 3 0 114 2.83V17a3 3 0 106 0v-2.17a3.001 3.001 0 010-5.66V7a5 5 0 1110 0v9.17a3.001 3.001 0 01-1 5.83 3 3 0 01-1-5.83V7a3 3 0 00-6 0v2.17a3.001 3.001 0 010 5.66V17a5 5 0 01-10 0V7.83a3.001 3.001 0 01-2-2.83zm3-1a1 1 0 100 2 1 1 0 000-2zm15 15a1 1 0 112 0 1 1 0 01-2 0z"
  })
}), 'JourneysIcon', 'Navigation');
export default JourneysIcon;