/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ConnectionsIcon = createIconsComponent(_jsx("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2 3.70532C2 2.60075 2.89543 1.70532 4 1.70532H28C29.1046 1.70532 30 2.60075 30 3.70532V6.70532C30 7.80989 29.1046 8.70532 28 8.70532H16.8335C15.7289 8.70532 14.8335 9.60075 14.8335 10.7053V18.3719C14.8335 19.4765 13.938 20.3719 12.8334 20.3719H4C2.89543 20.3719 2 19.4765 2 18.3719V3.70532ZM17.1665 13.0388C17.1665 11.9342 18.062 11.0388 19.1666 11.0388H28C29.1046 11.0388 30 11.9342 30 13.0388V27.7054C30 28.8099 29.1046 29.7054 28 29.7054H4C2.89543 29.7054 2 28.8099 2 27.7054V24.7054C2 23.6008 2.89543 22.7054 4 22.7054H15.1665C16.2711 22.7054 17.1665 21.8099 17.1665 20.7054V13.0388Z",
    fill: "#50D4A0"
  })
}), 'ConnectionsIcon', 'Segment');
export default ConnectionsIcon;