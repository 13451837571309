/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StoriesIcon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#StoriesIcon_svg__clip0)",
    children: [_jsx("path", {
      d: "M12.6666 16.6666C12.6666 14.4575 14.4575 12.6666 16.6666 12.6666H27C27.184 12.6666 27.3333 12.5174 27.3333 12.3333V2C27.3333 0.895429 26.4379 0 25.3333 0H2H2C0.895429 4.82822e-08 0 0.895429 0 2V21.3333H0C1.66785e-07 22.4379 0.895429 23.3333 2 23.3333H5.99999V28V27.9998C5.99999 28.2696 6.16258 28.5128 6.41189 28.6159C6.49274 28.6491 6.57926 28.6663 6.66666 28.6666C6.84378 28.6666 7.01361 28.5961 7.13865 28.4706L12.276 23.3333H12.3333C12.5174 23.3333 12.6666 23.1841 12.6666 23V16.6666ZM3.95999 13.292C4.35072 12.9028 4.9826 12.9028 5.37332 13.292H5.37332C6.46275 14.3924 7.88921 15.0965 9.42532 15.292C9.97318 15.3623 10.3603 15.8634 10.29 16.4113C10.2197 16.9592 9.71852 17.3463 9.17065 17.276C7.19575 17.0249 5.36155 16.1205 3.95999 14.7066C3.56934 14.3162 3.56914 13.6831 3.95954 13.2924C3.95969 13.2923 3.95984 13.2921 3.95999 13.292H3.95999ZM10.3333 8.66665C10.3333 9.21894 9.8856 9.66665 9.33332 9.66665C8.78103 9.66665 8.33332 9.21894 8.33332 8.66665V5.99999C8.33332 5.4477 8.78103 4.99999 9.33332 4.99999C9.8856 4.99999 10.3333 5.4477 10.3333 5.99999V8.66665ZM18.3333 8.66665C18.3333 9.21894 17.8856 9.66665 17.3333 9.66665C16.781 9.66665 16.3333 9.21894 16.3333 8.66665V5.99999C16.3333 5.4477 16.781 4.99999 17.3333 4.99999C17.8856 4.99999 18.3333 5.4477 18.3333 5.99999V8.66665Z",
      fill: "url(#StoriesIcon_svg__paint0_linear)"
    }), _jsx("path", {
      opacity: 0.4,
      d: "M30 14.667H16.6667C15.5621 14.667 14.6667 15.5624 14.6667 16.667V26.0003C14.6667 27.1049 15.5621 28.0003 16.6667 28.0003H21.7773L26.9333 31.867C27.2279 32.0879 27.6457 32.0282 27.8667 31.7336C27.9532 31.6182 28 31.4779 28 31.3336V28.0003H30C31.1046 28.0003 32 27.1049 32 26.0003V16.667C32 15.5624 31.1046 14.667 30 14.667C30 14.667 30 14.667 30 14.667ZM19.3333 21.3336C19.3333 20.5973 19.9303 20.0003 20.6667 20.0003C21.4031 20.0003 22 20.5973 22 21.3336C22 22.07 21.4031 22.667 20.6667 22.667C19.9303 22.667 19.3333 22.07 19.3333 21.3336ZM26 22.667C25.2636 22.667 24.6667 22.07 24.6667 21.3336C24.6667 20.5973 25.2636 20.0003 26 20.0003C26.7364 20.0003 27.3333 20.5973 27.3333 21.3336C27.3333 22.07 26.7364 22.667 26 22.667Z",
      fill: "url(#StoriesIcon_svg__paint1_linear)"
    })]
  }), _jsxs("defs", {
    children: [_jsxs("linearGradient", {
      id: "StoriesIcon_svg__paint0_linear",
      x1: 27.3333,
      y1: -0.00000134389,
      x2: 28.4367,
      y2: 27.5267,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "StoriesIcon_svg__paint1_linear",
      x1: 32,
      y1: 14.667,
      x2: 32.6362,
      y2: 31.3135,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsx("clipPath", {
      id: "StoriesIcon_svg__clip0",
      children: _jsx("rect", {
        width: 32,
        height: 32,
        fill: "white"
      })
    })]
  })]
}), 'StoriesIcon', 'Segment');
export default StoriesIcon;