/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ArrowRight = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M16.728 17.4615C16.4306 17.7498 16.4232 18.2246 16.7115 18.522C16.9998 18.8194 17.4746 18.8268 17.772 18.5385L23.6213 12.8679C23.7375 12.7595 23.8317 12.6285 23.8972 12.4818C23.9648 12.3305 24 12.1665 24 12C24 11.8335 23.9648 11.6695 23.8972 11.5182C23.8317 11.3715 23.7375 11.2405 23.6213 11.1321L17.772 5.46151C17.4746 5.1732 16.9998 5.18056 16.7115 5.47796C16.4232 5.77536 16.4306 6.25017 16.728 6.53849L21.5879 11.25H0.75C0.335786 11.25 0 11.5858 0 12C0 12.4142 0.335786 12.75 0.75 12.75H21.5879L16.728 17.4615Z",
    fill: "none"
  })
}), 'ArrowRight', 'Navigation');
export default ArrowRight;