import { useCallback, useState } from 'react';
import { trackButtonClick } from '@segment/tracking/scripts';

var useNavigation = function useNavigation(value) {
  var _useState = useState(value),
      activeDropdown = _useState[0],
      setActiveDropdown = _useState[1];

  var getDropdownLinkProps = useCallback(function (id) {
    return {
      open: activeDropdown === id,
      onClick: function onClick() {
        setActiveDropdown(activeDropdown === id ? undefined : id);
        trackButtonClick({
          buttonText: id + " dropdown",
          buttonCampaign: "nav"
        });
      },
      onClickAway: function onClickAway() {
        return setActiveDropdown(undefined);
      }
    };
  }, [activeDropdown]);
  return {
    activeDropdown: activeDropdown,
    setActiveDropdown: setActiveDropdown,
    getDropdownLinkProps: getDropdownLinkProps
  };
};

export default useNavigation;