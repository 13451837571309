import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import * as MobileNav from '@segment/matcha/recipes/Navigation/MobileNavigation';
import { trackButtonClick } from '@segment/tracking/scripts';
import { usePageConfig } from '@segment/contexts/page';
import docsData from '../../data/docs';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var Docs = function Docs(props) {
  var pageConfig = usePageConfig();

  var _docsData = docsData(pageConfig),
      content = _docsData.content,
      aside = _docsData.aside;

  return _jsx(MobileNav.PrimaryLink, _objectSpread(_objectSpread({
    title: content.title
  }, props), {}, {
    children: _jsxs("div", {
      children: [_jsxs(MobileNav.NavList, {
        children: [content.links.map(function (item) {
          return _jsx(MobileNav.NavItem, {
            role: "listitem",
            title: item.title,
            href: item.url,
            onClick: function onClick() {
              return trackButtonClick({
                buttonText: item.title,
                targetUrl: item.url,
                buttonCampaign: "nav"
              });
            }
          }, item.title);
        }), _jsx(MobileNav.NavItem, {
          role: "listitem",
          title: content.highlightedLink.title,
          href: content.highlightedLink.url,
          onClick: function onClick() {
            return trackButtonClick({
              buttonText: content.highlightedLink.url,
              targetUrl: content.highlightedLink.url,
              buttonCampaign: "nav"
            });
          }
        })]
      }), _jsx(MobileNav.Tray, {
        children: _jsxs("div", {
          children: [_jsx(MobileNav.Heading, {
            children: aside.title
          }), _jsx(MobileNav.NavList, {
            children: aside.links.map(function (item) {
              return _jsx(MobileNav.NavItem, {
                role: "listitem",
                title: item.title,
                description: item === null || item === void 0 ? void 0 : item.description,
                href: item.url,
                onClick: function onClick() {
                  return trackButtonClick({
                    buttonText: item.title,
                    targetUrl: item.url,
                    buttonCampaign: "nav"
                  });
                }
              }, item.title);
            })
          })]
        })
      })]
    })
  }));
};

export default Docs;