var utilityData = function utilityData(_ref) {
  var basePath = _ref.basePath,
      appUrl = _ref.appUrl;
  return {
    links: [{
      id: "support",
      title: "Support",
      links: [{
        title: "Help Center",
        url: "".concat(basePath, "/help/")
      }, {
        title: "Docs",
        url: "/docs/"
      }, {
        title: "API Status",
        url: "https://status.segment.com/"
      }, {
        title: "Contact Us",
        url: "".concat(basePath, "/contact/")
      }]
    }],
    signUpButton: {
      text: "Sign up",
      url: "".concat(basePath, "/signup/")
    },
    demoButton: {
      text: "See how it works",
      url: "".concat(basePath, "/demo/")
    },
    loginButton: {
      text: "Log in",
      url: "".concat(appUrl, "/login/")
    }
  };
};

export default utilityData;