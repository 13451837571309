/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var GdprIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11 17.554L12.0337 16.52C12.2929 16.261 12.8051 16.049 13.1717 16.049H14.7153C15.4492 16.049 16.0488 15.4486 16.0488 14.7153V13.1723C16.0488 12.8051 16.2612 12.2933 16.52 12.0345L17.6112 10.9435C18.1292 10.4243 18.1292 9.57545 17.6112 9.05704L16.52 7.96602C16.261 7.70701 16.0488 7.1952 16.0488 6.8282V5.28517C16.0488 4.55115 15.4492 3.95154 14.7153 3.95114H13.1717C12.8051 3.95114 12.2927 3.73854 12.0337 3.47973L11 2.44604V9.05661L13.0571 7L13.9997 7.94262L11 10.9419V17.554Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11 2.44614L10.9427 2.38881C10.4243 1.8704 9.57544 1.8704 9.05703 2.38881L7.96601 3.48063C7.70681 3.73963 7.1938 3.95164 6.82799 3.95164H5.28496C4.55195 3.95164 3.95154 4.55125 3.95154 5.28526V6.82829C3.95154 7.1939 3.73933 7.70731 3.48033 7.96611L2.38911 9.05713C1.8703 9.57554 1.8703 10.4244 2.38911 10.9436L3.48033 12.0346C3.73953 12.2936 3.95154 12.8068 3.95154 13.1724V14.7154C3.95154 15.4487 4.55195 16.0491 5.28496 16.0491H6.82799C7.1946 16.0491 7.70701 16.2613 7.96601 16.5201L9.05703 17.6115C9.57544 18.1295 10.4243 18.1295 10.9427 17.6115L11 17.5541V10.942L8.94143 13.0002L5.99958 10.058L6.94239 9.11533L8.94163 11.1146L11 9.0567V2.44614Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.94143 13.0001L5.99957 10.0579L6.94239 9.11524L8.94163 11.1145L13.0571 7L13.9997 7.94262L8.94143 13.0001Z",
    fill: "white"
  })]
}), 'GdprIcon', 'Navigation');
export default GdprIcon;