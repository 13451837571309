/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PartnerFinderIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 10,
  viewBox: "0 0 20 10",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M10.0274 7.51897L10.4836 7.09926C11.6515 6.03174 11.5785 5.9405 11.3595 5.65765C11.3595 5.64852 11.2866 5.56641 11.1953 5.44779C11.0402 5.46604 10.8851 5.47517 10.73 5.47517C9.5621 5.47517 8.60407 4.89122 8.15699 3.90582C7.73728 2.98428 7.85589 1.9715 8.46721 1.19595C8.47633 1.18683 8.49458 1.16858 8.5037 1.15946C8.37597 1.06821 8.26648 0.986097 8.16611 0.940476C7.60042 0.685001 6.97998 0.666753 6.38692 0.885732L3.65881 2.0445V8.01167H5.0548L6.58765 9.2708C7.07122 9.67226 7.49093 9.86387 7.91976 9.86387C8.48546 9.86387 9.0694 9.52628 9.85407 8.86022C9.67159 8.30365 9.60772 7.87481 10.0092 7.50072L10.0274 7.51897Z",
    fill: "#1C0732"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M13.4307 0.283615C12.8559 0.0646363 12.2172 0.0920087 11.6606 0.347484L10.1552 1.04092L10.1095 1.05917C9.89969 1.15041 9.73546 1.2599 9.58035 1.36939C9.5621 1.38763 9.54385 1.39676 9.5256 1.41501C9.38874 1.5245 9.27013 1.63399 9.17889 1.76172C8.73181 2.32742 8.74093 2.99348 8.98728 3.52268C9.334 4.28911 10.0639 4.55371 10.73 4.55371C10.8486 4.55371 10.9581 4.55371 11.0767 4.52633C11.2318 4.50808 11.396 4.46246 11.5603 4.42597C11.8705 4.80918 12.0803 5.06466 12.0895 5.07378C12.8194 6.03181 12.2263 6.71612 11.3687 7.50992C11.2683 7.60116 11.1497 7.71065 11.0311 7.82014C10.7847 8.04824 10.9763 8.26722 11.2866 8.20335C11.5968 8.13949 12.7647 7.90226 12.7647 7.90226L16.3595 7.17233V1.38763L13.4307 0.283615Z",
    fill: "#1C0732"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M2.74635 1.84379C2.74635 1.4332 2.41788 1.10474 2.0073 1.10474H0.456204C0.209854 1.10474 0 1.30547 0 1.56094V8.34017C0 8.58652 0.20073 8.79637 0.456204 8.79637H2.0073C2.41788 8.79637 2.74635 8.4679 2.74635 8.05732V1.84379Z",
    fill: "#1C0732"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M19.5438 0.457031H17.9927C17.5821 0.457031 17.2537 0.7855 17.2537 1.19609V7.40961C17.2537 7.8202 17.5821 8.14867 17.9927 8.14867H19.5438C19.7902 8.14867 20 7.94794 20 7.69246V0.913237C20 0.666886 19.7993 0.457031 19.5438 0.457031Z",
    fill: "#1C0732"
  })]
}), 'PartnerFinderIcon', 'Navigation');
export default PartnerFinderIcon;