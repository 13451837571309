/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RecipesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M10.5625 16.3436C10.5623 16.4472 10.6461 16.5313 10.7497 16.5314C10.7842 16.5315 10.818 16.522 10.8475 16.5041C11.8751 15.9263 12.9965 15.5341 14.1603 15.3454C14.2477 15.3272 14.3103 15.2501 14.3103 15.1609V11.8789C14.3107 11.3152 14.7673 10.8582 15.331 10.8574H17.875C18.0018 10.8574 18.2433 10.8536 18.4315 10.8574C18.5346 10.8611 18.6212 10.7805 18.6249 10.6775C18.625 10.6749 18.625 10.6724 18.625 10.6699V2.42506V2.42505C18.625 2.01083 18.2892 1.67505 17.875 1.67505C17.8735 1.67505 17.872 1.67505 17.8705 1.67506H17.8645C13.9548 1.72681 11.7603 2.55331 10.6585 3.17506C10.5993 3.20818 10.5626 3.2707 10.5625 3.33856L10.5625 16.3436Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M9.4375 3.33243C9.43755 3.26435 9.40084 3.20154 9.34149 3.16818C8.23975 2.55019 6.0445 1.72368 2.13325 1.67493C1.93139 1.65577 1.73273 1.7354 1.6 1.88868C1.4565 2.03028 1.3755 2.22333 1.375 2.42493V14.2464V14.2465C1.375 14.6566 1.70447 14.9907 2.11457 14.9964C6.15557 15.0481 8.24957 15.9549 9.15257 16.5054C9.24028 16.5597 9.35538 16.5325 9.40964 16.4448C9.42821 16.4148 9.43789 16.3802 9.43757 16.3449L9.4375 3.33243Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M15.625 11.9823C15.5214 11.9823 15.4375 12.0662 15.4375 12.1698V14.6703C15.4377 14.7738 15.5218 14.8576 15.6254 14.8574C15.6736 14.8573 15.7199 14.8386 15.7547 14.8053C16.366 14.2195 17.6792 12.9055 18.271 12.2965C18.3431 12.2216 18.3408 12.1024 18.2658 12.0303C18.2311 11.9969 18.1849 11.9781 18.1367 11.9778L15.625 11.9823Z",
    fill: "none"
  })]
}), 'RecipesIcon', 'Navigation');
export default RecipesIcon;