import { ConnectionsIcon, EngageIcon, ProtocolsIcon, UnifyIcon } from '@segment/matcha/components/Icons/Segment';

var productData = function productData(_ref) {
  var basePath = _ref.basePath;
  return {
    content: {
      title: "Products",
      links: [{
        title: "Connections",
        description: "Connect all of your customer data",
        icon: ConnectionsIcon,
        id: "connections"
      }, {
        title: "Protocols",
        description: "Take control of your data quality",
        icon: ProtocolsIcon,
        id: "protocols"
      }, {
        title: "Unify",
        description: "Unify customer data across every channel",
        icon: UnifyIcon,
        id: "unify"
      }, {
        title: "Twilio Engage",
        description: "Build personalized customer experiences",
        icon: EngageIcon,
        id: "twilio-engage"
      }]
    },
    bottomLinks: [{
      title: "Integrations",
      id: "integrations"
    }, {
      title: "Privacy & Security",
      id: "privacy-security"
    }, {
      title: "Why Twilio Segment",
      id: "why-twilio-segment"
    }],
    aside: [{
      title: "Connections Features",
      id: "connections",
      links: [{
        title: "Connections Overview",
        description: "Integrate web and mobile app data with a single API",
        url: "".concat(basePath, "/product/connections/")
      }, {
        title: "Warehouses",
        description: "Easily transform and load customer data",
        url: "".concat(basePath, "/product/warehouses/")
      }, {
        title: "Reverse ETL",
        description: "Move warehouse data to your applications",
        url: "".concat(basePath, "/product/reverse-etl/")
      }, {
        title: "Functions",
        description: "Customize your customer data pipeline",
        url: "".concat(basePath, "/product/connections/functions/")
      }, {
        title: "Developer Toolkit",
        description: "Build on Twilio Segment",
        url: "".concat(basePath, "/product/developer-toolkit/")
      }]
    }, {
      title: "Protocols Features",
      id: "protocols",
      links: [{
        title: "Protocols Product Overview",
        description: "Protect the integrity of your data",
        url: "".concat(basePath, "/product/protocols/")
      }, {
        title: "Privacy Portal",
        description: "Protect users\u2019 privacy",
        url: "".concat(basePath, "/product/privacy-portal/")
      }]
    }, {
      title: "Unify Features",
      id: "unify",
      links: [{
        title: "Unify Overview",
        description: "Get a complete view of your customer",
        url: "".concat(basePath, "/product/unify/")
      }, {
        title: "Profiles Sync",
        description: "Sync customer profiles to the warehouse",
        url: "".concat(basePath, "/product/profiles-sync/")
      }]
    }, {
      title: "Twilio Engage Features",
      id: "twilio-engage",
      links: [{
        title: "Twilio Engage Overview",
        description: "Deepen customer relationships with data-first multichannel marketing",
        url: "".concat(basePath, "/product/twilio-engage/")
      }, {
        title: "Audiences",
        description: "Build dynamic audiences from real-time, complete profiles",
        url: "".concat(basePath, "/product/twilio-engage/audiences/")
      }, {
        title: "Journeys",
        description: "Orchestrate multichannel customer journeys",
        url: "".concat(basePath, "/product/twilio-engage/journeys/")
      }]
    }, {
      title: "Integrations",
      id: "integrations",
      links: [{
        title: "Advertising",
        description: "",
        url: "".concat(basePath, "/catalog/advertising/")
      }, {
        title: "Analytics",
        description: "",
        url: "".concat(basePath, "/catalog/analytics/")
      }, {
        title: "BI Tools",
        description: "",
        url: "".concat(basePath, "/catalog/bi-tools/")
      }, {
        title: "CRM",
        description: "",
        url: "".concat(basePath, "/catalog/crm/")
      }, {
        title: "Data Warehouses",
        description: "",
        url: "".concat(basePath, "/catalog/warehouse/")
      }, {
        title: "Email Marketing",
        description: "",
        url: "".concat(basePath, "/catalog/email-marketing/")
      }, {
        title: "Marketing Automation",
        description: "",
        url: "".concat(basePath, "/catalog/marketing-automation/")
      }, {
        title: "Personalization",
        description: "",
        url: "".concat(basePath, "/catalog/personalization/")
      }, {
        title: "View All Integrations",
        description: "",
        url: "".concat(basePath, "/catalog/")
      }]
    }, {
      title: "Privacy & Security",
      id: "privacy-security",
      links: [{
        title: "Trust Center",
        description: "",
        url: "".concat(basePath, "/trust-center/")
      }, {
        title: "Privacy Portal",
        description: "",
        url: "".concat(basePath, "/product/privacy-portal/")
      }, {
        title: "Security",
        description: "",
        url: "".concat(basePath, "/security/")
      }, {
        title: "GDPR",
        description: "",
        url: "".concat(basePath, "/product/gdpr/")
      }]
    }, {
      title: "Why Twilio Segment",
      id: "why-twilio-segment",
      links: [{
        title: "Open Source",
        description: "",
        url: "".concat(basePath, "/opensource/")
      }, {
        title: "Infrastructure",
        description: "",
        url: "".concat(basePath, "/infrastructure/")
      }, {
        title: "Twilio + Segment",
        description: "",
        url: "".concat(basePath, "/twilio/")
      }]
    }]
  };
};

export default productData;