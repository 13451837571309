import { ArrowRight } from '@segment/matcha/components/Icons/Navigation';
import * as DesktopNav from '@segment/matcha/recipes/Navigation/DesktopNavigation';
import NavDropdownBase, * as NavDropdown from '@segment/matcha/recipes/Navigation/DesktopNavigation/Dropdown';
import useNavigation from '@segment/matcha/recipes/Navigation/useNavigation';
import { trackButtonClick } from '@segment/tracking/scripts';
import { usePageConfig } from '@segment/contexts/page';
import resourcesData from '../../data/resources';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var Resources = function Resources() {
  var pageConfig = usePageConfig();

  var _resourcesData = resourcesData(pageConfig),
      content = _resourcesData.content,
      aside = _resourcesData.aside;

  var _useNavigation = useNavigation(content.links[0].id),
      activeDropdown = _useNavigation.activeDropdown,
      setActiveDropdown = _useNavigation.setActiveDropdown;

  return _jsxs(NavDropdownBase, {
    children: [_jsxs(NavDropdown.Content, {
      children: [_jsx(DesktopNav.Subheading, {
        children: content.title
      }), _jsx(DesktopNav.List, {
        children: content.links.map(function (item) {
          return _jsx("li", {
            role: "none",
            children: _jsx(DesktopNav.Link, {
              "aria-haspopup": "true",
              "aria-expanded": activeDropdown === item.id,
              iconEnd: activeDropdown === item.id ? ArrowRight : undefined,
              onClick: function onClick() {
                setActiveDropdown(item.id);
                trackButtonClick({
                  buttonText: item.title,
                  buttonCampaign: "nav"
                });
              },
              title: item.title
            })
          }, item.title);
        })
      })]
    }), _jsx(NavDropdown.Tray, {
      children: aside.map(function (_ref) {
        var title = _ref.title,
            links = _ref.links,
            id = _ref.id;
        return activeDropdown === id && _jsxs("div", {
          children: [_jsx(DesktopNav.Subheading, {
            children: title
          }), _jsx(DesktopNav.List, {
            children: links.map(function (item) {
              return _jsx("li", {
                role: "none",
                children: _jsx(DesktopNav.Link, {
                  title: item.title,
                  description: item.description,
                  href: item.url,
                  onClick: function onClick() {
                    return trackButtonClick({
                      buttonText: item.title,
                      targetUrl: item.url,
                      buttonCampaign: "nav"
                    });
                  }
                })
              }, item.title);
            })
          })]
        }, title);
      })
    })]
  });
};

export default Resources;