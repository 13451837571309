/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PressIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M10.1034 12.5501L6.94889 15.7001C6.31621 16.3606 5.31069 16.4954 4.52639 16.0249C3.65814 15.4492 3.42096 14.2787 3.99662 13.4104C4.06351 13.3095 4.13995 13.2153 4.22489 13.1291L7.44989 9.8981C7.67885 8.14918 8.29368 6.47301 9.24989 4.99085C9.30664 4.90673 9.28445 4.79252 9.20033 4.73576C9.17063 4.71573 9.13571 4.70481 9.09989 4.70435H2.17139C1.55007 4.70435 1.04639 5.20803 1.04639 5.82935V17.8294C1.04639 18.4507 1.55007 18.9544 2.17139 18.9544H14.1714C14.7927 18.9544 15.2964 18.4507 15.2964 17.8294V10.9046C15.2951 10.8031 15.2118 10.7219 15.1103 10.7232C15.0745 10.7236 15.0396 10.7346 15.0099 10.7546C13.5275 11.709 11.8517 12.3223 10.1034 12.5501Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M18.5649 1.04576C16.9131 1.1063 15.296 1.53635 13.8324 2.30426C13.7613 2.3421 13.7212 2.42017 13.7319 2.50001L13.9749 4.33976C14.0025 4.54501 13.8585 4.73381 13.6533 4.76145C13.5625 4.77367 13.4704 4.75226 13.3944 4.70126L11.9926 3.76001C11.9185 3.70891 11.8184 3.71874 11.7556 3.78326C11.4879 4.05701 10.8069 4.75826 10.7604 4.81301C10.2661 5.43033 9.84666 6.10403 9.51085 6.82001C9.24037 7.39444 9.02151 7.99179 8.85685 8.60501C8.77485 8.90501 8.70585 9.21001 8.64985 9.52001C8.62285 9.67001 8.5981 9.82001 8.57485 9.97001C8.5636 10.0525 8.57485 10.3638 8.52085 10.42L5.0161 13.924C4.72331 14.2169 4.72331 14.6916 5.0161 14.9845C5.31098 15.2724 5.78172 15.2724 6.0766 14.9845L9.5836 11.4775C11.9843 11.2609 14.2375 10.225 15.9646 8.54351C17.8179 6.63293 18.8848 4.09515 18.9534 1.43426C18.9572 1.33008 18.9176 1.22899 18.8439 1.15526C18.7705 1.08101 18.6692 1.04124 18.5649 1.04576V1.04576Z",
    fill: "none"
  })]
}), 'PressIcon', 'Navigation');
export default PressIcon;