/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var OpenSourceIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19.6741 5.65701C20.0807 5.25068 20.0809 4.59167 19.6746 4.18505C19.6744 4.18487 19.6742 4.18469 19.6741 4.18451L16.3407 0.851178C15.9412 0.437263 15.2817 0.425615 14.8678 0.825165C14.4539 1.22471 14.4423 1.88415 14.8418 2.29807C14.8502 2.30675 14.8587 2.31529 14.8674 2.32367L17.1666 4.62617C17.3292 4.78888 17.3292 5.05263 17.1666 5.21534L14.8674 7.51784C14.4679 7.93175 14.4795 8.5912 14.8934 8.99075C15.2972 9.38051 15.9372 9.38033 16.3407 8.99034L19.6741 5.65701Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M8.84086 0.851195C8.43477 0.444344 7.77575 0.44373 7.3689 0.849822C7.36844 0.850278 7.36798 0.850736 7.36753 0.851195L4.03419 4.18453C3.62757 4.59085 3.62733 5.24987 4.03366 5.65648C4.03384 5.65666 4.03401 5.65684 4.03419 5.65702L7.36753 8.99035C7.76708 9.40427 8.42652 9.41592 8.84043 9.01637C9.25435 8.61682 9.266 7.95738 8.86645 7.54346C8.85807 7.53478 8.84954 7.52624 8.84086 7.51786L6.53836 5.21536C6.3757 5.05265 6.3757 4.7889 6.53836 4.62619L8.84086 2.32369C9.24748 1.91737 9.24772 1.25835 8.8414 0.851736C8.84122 0.851556 8.84104 0.851376 8.84086 0.851196V0.851195Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.795 9.68775C10.8695 9.70398 10.9455 9.71208 11.0217 9.71191C11.5094 9.71127 11.9316 9.37282 12.0383 8.89691L13.705 1.39692C13.83 0.834046 13.475 0.276457 12.9121 0.151499C12.3492 0.0265453 11.7916 0.381546 11.6667 0.944415L10 8.44441C9.87613 9.00716 10.2319 9.56378 10.7946 9.68766C10.7948 9.68769 10.7949 9.68772 10.795 9.68775Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.14081 13.2512C3.62324 13.2514 3.11282 13.3721 2.64998 13.6037L0.135813 14.8629C0.0651914 14.8983 0.0206584 14.9705 0.0208134 15.0495V19.5445H0.0208134C0.0209566 19.6596 0.114347 19.7528 0.229406 19.7526C0.268197 19.7526 0.306204 19.7417 0.339147 19.7212L3.18748 17.9412C3.29311 17.8752 3.42272 17.8599 3.54081 17.8995C12.0841 20.7629 9.18497 20.812 18.6241 16.0362C18.8345 15.928 18.9173 15.6697 18.8091 15.4594C18.7812 15.4051 18.742 15.3574 18.6941 15.3195C18.1421 14.8406 17.3782 14.6888 16.685 14.9204L13.8333 15.867C13.728 15.9021 13.641 15.9777 13.5916 16.077C13.3117 16.6427 12.7353 17.0008 12.1041 17.0012H8.77081C8.54069 17.0012 8.35414 16.8147 8.35414 16.5845C8.35414 16.3544 8.54069 16.1679 8.77081 16.1679H12.1041C12.5644 16.1679 12.9375 15.7948 12.9375 15.3345C12.9375 14.8743 12.5644 14.5012 12.1041 14.5012H9.33331C9.24315 14.5012 9.15543 14.472 9.08331 14.4179C8.14977 13.7129 7.02514 13.3063 5.85664 13.2512H4.14081Z",
    fill: "none"
  })]
}), 'OpenSourceIcon', 'Navigation');
export default OpenSourceIcon;