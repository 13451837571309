/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MarketplacesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.9066 6.4184L16.6882 4.1224C16.6597 4.09283 16.6258 3.68052 16.5892 3.16341C16.4979 1.87034 15.5235 1 14.2272 1C10.833 1 8.29122 1 4.88138 1C3.63035 1 2.6021 1.80957 2.56424 3.06002C2.547 3.62963 2.54554 4.0878 2.51218 4.1224L0.271381 6.4424C-0.148355 6.95528 -0.0728462 7.71132 0.440036 8.13106C0.654488 8.30656 0.92307 8.40244 1.20018 8.4024H18.0002C18.6629 8.40204 19.1999 7.86449 19.1995 7.20175C19.1994 6.92491 19.1035 6.65664 18.9282 6.4424C18.9218 6.4344 18.9138 6.4264 18.9066 6.4184Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.4004 9.60229H2.80039C2.57948 9.60229 2.40039 9.78138 2.40039 10.0023V16.8023C2.40039 17.465 2.93765 18.0023 3.60039 18.0023H7.20039C7.42131 18.0023 7.60039 17.8232 7.60039 17.6023V13.6023C7.60039 12.4977 8.49583 11.6023 9.60039 11.6023C10.705 11.6023 11.6004 12.4977 11.6004 13.6023V17.6023C11.6004 17.8232 11.7795 18.0023 12.0004 18.0023H15.6004C16.2631 18.0023 16.8004 17.465 16.8004 16.8023V10.0023C16.8004 9.78139 16.6213 9.60231 16.4004 9.60231V9.60229Z",
    fill: "none"
  })]
}), 'MarketplacesIcon', 'Navigation');
export default MarketplacesIcon;