/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SupportIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M15.143 6.487a.806.806 0 00.557.527.788.788 0 00.87-.291.806.806 0 00.124-.716 6.981 6.981 0 00-13.393 0 .772.772 0 00.129.699.806.806 0 00.643.33.806.806 0 00.784-.544 5.344 5.344 0 0110.252 0l.034-.005z"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M4.026 8.253h-.052a1.68 1.68 0 00-1.633 1.285h-.805a.536.536 0 00-.536.536v4.072a.536.536 0 00.536.535h.805a1.68 1.68 0 001.633 1.286h.052a1.687 1.687 0 001.688-1.693V9.941a1.684 1.684 0 00-1.688-1.688zM18.464 9.538h-.805a1.68 1.68 0 00-1.633-1.285h-.052a1.685 1.685 0 00-1.688 1.688v4.333a1.688 1.688 0 001.037 1.56 1.595 1.595 0 01-1.539 1.2h-2.901a1.5 1.5 0 100 1.286h2.901a2.888 2.888 0 002.85-2.473 1.68 1.68 0 001.025-1.166h.805a.536.536 0 00.536-.535v-4.072a.536.536 0 00-.536-.536z"
  })]
}), 'SupportIcon', 'Navigation');
export default SupportIcon;