/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var DocumentationIcon = createIconsComponent(_jsx("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M28 20.831V7.68842C28.0026 6.70286 27.6152 5.75514 26.92 5.04676L22.92 1.10398C22.2119 0.406285 21.2545 0.00995131 20.2533 8.93997e-08H13.1734L13.1467 0C11.0333 0.00726786 9.326 1.70189 9.33337 3.78506V3.78507V5.25704C9.33337 5.98289 9.93032 6.5713 10.6667 6.5713H15.7733C16.9575 6.58091 18.0893 7.05364 18.92 7.88556L23.5466 12.354C23.8372 12.6341 24.0007 13.018 24 13.4186V22.1453C24 22.8711 24.5969 23.4595 25.3333 23.4595C26.8061 23.4595 28 22.2827 28 20.831ZM17.5466 9.00269L21.5466 12.9455L21.5999 12.9061C22.2943 13.6246 22.677 14.5817 22.6666 15.574V27.5995C22.6669 29.6645 20.988 31.3487 18.8933 31.3845H7.83999C5.71922 31.3845 4 29.6899 4 27.5995V11.6707C4.00735 9.58534 5.72442 7.8987 7.83999 7.89871H14.88C15.8817 7.90584 16.8401 8.3026 17.5466 9.00269Z",
    fill: "#53ADDF"
  })
}), 'DocumentationIcon', 'Segment');
export default DocumentationIcon;