/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LibraryIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M17.3333 2C17.1565 2 16.9869 2.07024 16.8619 2.19526C16.7369 2.32029 16.6667 2.48986 16.6667 2.66667V6C16.6667 6.26522 16.5613 6.51957 16.3738 6.70711C16.1862 6.89464 15.9319 7 15.6667 7C15.4014 7 15.1471 6.89464 14.9596 6.70711C14.772 6.51957 14.6667 6.26522 14.6667 6V2.66667C14.6667 2.48986 14.5964 2.32029 14.4714 2.19526C14.3464 2.07024 14.1768 2 14 2C13.8232 2 13.6536 2.07024 13.5286 2.19526C13.4036 2.32029 13.3333 2.48986 13.3333 2.66667V12.54C13.3336 12.576 13.3452 12.611 13.3667 12.64C13.6523 12.9962 14.0161 13.2818 14.4298 13.4749C14.8436 13.6679 15.2962 13.7632 15.7527 13.7533C16.1606 13.7595 16.5657 13.6835 16.9437 13.5298C17.3216 13.3761 17.6648 13.1478 17.9527 12.8587C17.9827 12.8278 17.9995 12.7864 17.9993 12.7433V2.66667C17.9993 2.48997 17.9292 2.3205 17.8043 2.1955C17.6794 2.07049 17.51 2.00018 17.3333 2V2Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M17.7533 14.2527C17.1092 14.5966 16.3874 14.7687 15.6574 14.7524C14.9275 14.736 14.214 14.5318 13.586 14.1594C13.5607 14.1442 13.5318 14.1359 13.5023 14.1355C13.4728 14.1351 13.4437 14.1425 13.418 14.157C13.3924 14.1715 13.371 14.1926 13.3561 14.2181C13.3412 14.2436 13.3333 14.2726 13.3333 14.3021V15.6667C13.3333 16.2856 13.5792 16.8791 14.0167 17.3166C14.4543 17.7542 15.0478 18.0001 15.6667 18.0001C16.2855 18.0001 16.879 17.7542 17.3166 17.3166C17.7542 16.8791 18 16.2856 18 15.6667V14.4001C18.0001 14.3711 17.9926 14.3426 17.9783 14.3174C17.964 14.2922 17.9434 14.2712 17.9185 14.2564C17.8936 14.2415 17.8653 14.2335 17.8363 14.233C17.8073 14.2325 17.7787 14.2395 17.7533 14.2534V14.2527Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M11.6667 2C11.4899 2 11.3203 2.07024 11.1953 2.19526C11.0702 2.32029 11 2.48986 11 2.66667V6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73479 7 9.48043 6.89464 9.2929 6.70711C9.10536 6.51957 9.00001 6.26522 9.00001 6V2.66667C9.00001 2.48986 8.92977 2.32029 8.80474 2.19526C8.67972 2.07024 8.51015 2 8.33334 2C8.15653 2 7.98696 2.07024 7.86193 2.19526C7.73691 2.32029 7.66667 2.48986 7.66667 2.66667V12.54C7.66691 12.576 7.67858 12.611 7.70001 12.64C7.98566 12.9962 8.34944 13.2818 8.76318 13.4749C9.17693 13.6679 9.62954 13.7632 10.086 13.7533C10.494 13.7595 10.899 13.6835 11.277 13.5298C11.655 13.3761 11.9981 13.1478 12.286 12.8587C12.316 12.8278 12.3328 12.7864 12.3327 12.7433V2.66667C12.3327 2.48997 12.2625 2.3205 12.1376 2.1955C12.0128 2.07049 11.8434 2.00018 11.6667 2V2Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M12.0867 14.2527C11.4426 14.5966 10.7207 14.7687 9.99077 14.7524C9.26081 14.736 8.54738 14.5318 7.91934 14.1594C7.89406 14.1442 7.86519 14.1359 7.83569 14.1355C7.80618 14.1351 7.77709 14.1425 7.75139 14.157C7.72569 14.1715 7.70431 14.1926 7.68943 14.2181C7.67454 14.2436 7.66669 14.2726 7.66667 14.3021V15.6667C7.66667 16.2856 7.9125 16.8791 8.35009 17.3166C8.78767 17.7542 9.38117 18.0001 10 18.0001C10.6188 18.0001 11.2123 17.7542 11.6499 17.3166C12.0875 16.8791 12.3333 16.2856 12.3333 15.6667V14.4001C12.3334 14.3711 12.3259 14.3426 12.3117 14.3174C12.2974 14.2922 12.2768 14.2712 12.2519 14.2564C12.227 14.2415 12.1986 14.2335 12.1697 14.233C12.1407 14.2325 12.1121 14.2395 12.0867 14.2534V14.2527Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M6 2C5.82319 2 5.65362 2.07024 5.5286 2.19526C5.40357 2.32029 5.33333 2.48986 5.33333 2.66667V6C5.33333 6.26522 5.22798 6.51957 5.04044 6.70711C4.8529 6.89464 4.59855 7 4.33333 7C4.06812 7 3.81376 6.89464 3.62623 6.70711C3.43869 6.51957 3.33333 6.26522 3.33333 6V2.66667C3.33333 2.48986 3.2631 2.32029 3.13807 2.19526C3.01305 2.07024 2.84348 2 2.66667 2C2.48986 2 2.32029 2.07024 2.19526 2.19526C2.07024 2.32029 2 2.48986 2 2.66667V12.54C2.00014 12.5761 2.01208 12.6113 2.034 12.64C2.31959 12.9962 2.68335 13.282 3.09711 13.475C3.51087 13.6681 3.96352 13.7633 4.42 13.7533C4.82797 13.7594 5.23298 13.6833 5.61094 13.5296C5.98889 13.3759 6.33206 13.1477 6.62 12.8587C6.64986 12.8277 6.66658 12.7864 6.66667 12.7433V2.66667C6.66667 2.48986 6.59643 2.32029 6.4714 2.19526C6.34638 2.07024 6.17681 2 6 2V2Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M6.42067 14.2526C5.80596 14.5831 5.11858 14.7552 4.42067 14.7533C3.65775 14.7569 2.90841 14.5515 2.254 14.1593C2.22867 14.1441 2.19978 14.1359 2.17025 14.1355C2.14073 14.1351 2.11162 14.1425 2.08589 14.157C2.06016 14.1715 2.03873 14.1926 2.02376 14.218C2.00879 14.2435 2.00083 14.2724 2.00067 14.302V15.6666C2.00067 16.2855 2.2465 16.879 2.68409 17.3165C3.12167 17.7541 3.71517 18 4.334 18C4.95284 18 5.54634 17.7541 5.98392 17.3165C6.42151 16.879 6.66734 16.2855 6.66734 15.6666V14.4C6.66735 14.3711 6.65987 14.3427 6.64561 14.3176C6.63136 14.2925 6.61082 14.2715 6.58602 14.2567C6.56122 14.2419 6.533 14.2338 6.50413 14.2332C6.47526 14.2326 6.44673 14.2395 6.42134 14.2533L6.42067 14.2526Z",
    fill: "none"
  })]
}), 'LibraryIcon', 'Navigation');
export default LibraryIcon;