/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var FunctionsIcon = createIconsComponent(_jsx("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M6 6C4.89543 6 4 6.89543 4 8V23.3548C4 24.4594 4.89543 25.3548 6 25.3548H26C27.1046 25.3548 28 24.4594 28 23.3548V8C28 6.89543 27.1046 6 26 6H6ZM8.4801 10.3377C8.88884 10.3377 9.26524 10.1153 9.46255 9.7572C9.57411 9.58358 9.63596 9.3827 9.64139 9.17638C9.63614 8.97003 9.57427 8.7691 9.46255 8.59556C9.26524 8.23748 8.88884 8.01509 8.4801 8.01509C8.07135 8.01509 7.69495 8.23748 7.49764 8.59556C7.38592 8.7691 7.32405 8.97003 7.31881 9.17638C7.32423 9.3827 7.38608 9.58358 7.49764 9.7572C7.69495 10.1153 8.07135 10.3377 8.4801 10.3377ZM13.3332 9.7572C13.1359 10.1153 12.7595 10.3377 12.3507 10.3377C11.942 10.3377 11.5656 10.1153 11.3683 9.7572C11.2567 9.58358 11.1949 9.3827 11.1894 9.17638C11.1947 8.97003 11.2566 8.7691 11.3683 8.59556C11.5656 8.23748 11.942 8.01509 12.3507 8.01509C12.7595 8.01509 13.1359 8.23748 13.3332 8.59556C13.4449 8.7691 13.5068 8.97003 13.512 9.17638C13.5066 9.3827 13.4447 9.58358 13.3332 9.7572ZM16.2218 10.3377C16.6305 10.3377 17.0069 10.1153 17.2042 9.75729C17.3158 9.5837 17.3776 9.38285 17.3831 9.17656C17.3819 9.01239 17.3454 8.85039 17.2762 8.70152C17.0899 8.28561 16.6775 8.01716 16.2218 8.01509C15.8141 8.01932 15.4396 8.24067 15.2393 8.59582C15.1276 8.76934 15.0657 8.97024 15.0605 9.17656C15.0659 9.38285 15.1278 9.5837 15.2393 9.75729C15.4366 10.1153 15.813 10.3377 16.2218 10.3377ZM11.7345 20.7057C12.1377 21.0949 12.7912 21.0949 13.1943 20.7057L15.7752 18.2127C16.1782 17.8233 16.1782 17.1921 15.7752 16.8027L13.1943 14.3098C12.7892 13.9319 12.1453 13.9373 11.7471 14.3219C11.3489 14.7066 11.3433 15.3285 11.7345 15.7198L13.4028 17.3312C13.4514 17.378 13.4787 17.4415 13.4787 17.5077C13.4787 17.574 13.4514 17.6375 13.4028 17.6842L11.7345 19.2957C11.3315 19.6851 11.3315 20.3163 11.7345 20.7057ZM22.787 21.7718H18.658C18.0879 21.7718 17.6257 21.4252 17.6257 20.9976C17.6257 20.57 18.0879 20.2234 18.658 20.2234H22.787C23.3571 20.2234 23.8193 20.57 23.8193 20.9976C23.8193 21.4252 23.3571 21.7718 22.787 21.7718Z",
    fill: "#8192F1"
  })
}), 'FunctionsIcon', 'Segment');
export default FunctionsIcon;