/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MarketingIcon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M11.6247 14.164C11.1512 11.3232 13.1199 9 15.9999 9C18.8787 9 20.8475 11.3232 20.3739 14.164L19.6575 18.4648C19.3739 20.1708 17.7275 21.5639 15.9999 21.5639C14.2703 21.5639 12.6275 20.1708 12.3435 18.4648L11.6247 14.164Z",
    fill: "url(#MarketingIcon_svg__paint0_linear)"
  }), _jsx("path", {
    d: "M18.671 22.8502C19.5428 22.3636 20.5642 22.0772 21.5115 22.3928L24.9805 23.5485C25.9077 23.8585 26.6669 24.9116 26.6669 25.8896V27.667C26.6669 29.1397 25.473 30.3336 24.0003 30.3336H8.00006C6.52731 30.3336 5.3334 29.1397 5.3334 27.667V25.8896C5.3334 24.9116 6.0942 23.8581 7.021 23.5485L10.4888 22.3929C11.4361 22.0772 12.4576 22.3636 13.3295 22.8503C14.1385 23.3019 15.0517 23.5641 16.0002 23.5641C16.9487 23.5641 17.8619 23.3019 18.671 22.8502Z",
    fill: "url(#MarketingIcon_svg__paint1_linear)"
  }), _jsxs("g", {
    opacity: 0.4,
    children: [_jsx("path", {
      d: "M13.9631 7.31417C14.5044 7.14756 14.9479 6.72276 15.0404 6.16398C15.5148 3.32279 13.546 1 10.6668 1C7.78641 1 5.81762 3.32319 6.29162 6.16398L7.01041 10.4648C7.02768 10.5689 7.05003 10.6719 7.07718 10.7733C7.55048 12.5421 9.58329 11.359 10.582 9.82437C10.7177 9.61591 10.8666 9.4155 11.0284 9.22437C11.7951 8.31936 12.814 7.66785 13.9631 7.31417Z",
      fill: "url(#MarketingIcon_svg__paint2_linear)"
    }), _jsx("path", {
      d: "M9.43914 20.6349C10.1763 20.3897 10.4967 19.5593 10.37 18.7928L10.1296 17.3512C9.94221 16.2273 9.01092 15.392 8.01006 14.8476C7.13312 14.3705 6.10284 14.0772 5.15573 14.3928L1.68759 15.5485C0.760397 15.8581 0 16.9113 0 17.8892V19.6666C0 21.1393 1.1939 22.3332 2.66666 22.3332H4.21834C4.81421 22.3332 5.37933 22.1056 5.91481 21.8442C6.06739 21.7697 6.22446 21.7049 6.38557 21.6508L9.43914 20.6349Z",
      fill: "url(#MarketingIcon_svg__paint3_linear)"
    }), _jsx("path", {
      d: "M21.3331 6.33332C19.8864 6.33332 20.0354 8.11976 20.9699 9.22411C22.1679 10.6381 22.6691 12.5573 22.3463 14.4921L21.6299 18.7929C21.6278 18.8069 21.6234 18.8206 21.619 18.8344C21.6134 18.8518 21.6274 18.8681 21.6457 18.8663C23.2554 18.7103 24.7243 17.3992 24.9907 15.7981L25.7067 11.4973C26.1807 8.65611 24.2119 6.33332 21.3331 6.33332Z",
      fill: "url(#MarketingIcon_svg__paint4_linear)"
    }), _jsx("path", {
      d: "M25.7463 19.3602C25.4735 19.2693 25.1749 19.3422 24.9536 19.5259C24.3689 20.0116 24.4061 21.2491 25.1272 21.4894L25.612 21.651C27.354 22.233 28.6668 24.0546 28.6668 25.8894V26C28.6668 26.9204 29.413 27.6665 30.3334 27.6665C31.2538 27.6665 32 26.9204 32 26V23.2226C32 22.2446 31.2412 21.1914 30.3136 20.8814L25.7463 19.3602Z",
      fill: "url(#MarketingIcon_svg__paint5_linear)"
    })]
  }), _jsxs("defs", {
    children: [_jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint0_linear",
      x1: 26.6669,
      y1: 9,
      x2: 27.45,
      y2: 29.4883,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint1_linear",
      x1: 26.6669,
      y1: 9,
      x2: 27.45,
      y2: 29.4883,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint2_linear",
      x1: 32,
      y1: 0.999999,
      x2: 32.8161,
      y2: 26.6213,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint3_linear",
      x1: 32,
      y1: 0.999999,
      x2: 32.8161,
      y2: 26.6213,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint4_linear",
      x1: 32,
      y1: 0.999999,
      x2: 32.8161,
      y2: 26.6213,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "MarketingIcon_svg__paint5_linear",
      x1: 32,
      y1: 0.999999,
      x2: 32.8161,
      y2: 26.6213,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    })]
  })]
}), 'MarketingIcon', 'Segment');
export default MarketingIcon;