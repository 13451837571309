var docsData = function docsData(_ref) {
  var basePath = _ref.basePath;
  return {
    content: {
      title: "Docs",
      links: [{
        title: "Public API",
        url: "https://docs.segmentapis.com/"
      }, {
        title: "Javascript",
        url: "/docs/connections/sources/catalog/libraries/website/javascript/"
      }, {
        title: "iOS",
        url: "/docs/connections/sources/catalog/libraries/mobile/ios/"
      }, {
        title: "All SDKs",
        url: "/docs/connections/sources/catalog/"
      }, {
        title: "Recipes",
        url: "".concat(basePath, "/recipes/")
      }, {
        title: "API Status",
        url: "https://status.segment.com/"
      }, {
        title: "Integrations",
        url: "".concat(basePath, "/catalog/")
      }, {
        title: "Security",
        url: "".concat(basePath, "/trust-center/")
      }],
      highlightedLink: {
        title: "View All Docs",
        url: "/docs/"
      }
    },
    aside: {
      title: "Popular Topics",
      links: [{
        title: "Spec",
        description: "Guidance on capturing and formatting data",
        url: "/docs/connections/spec/"
      }, {
        title: "Sources",
        description: "Applications which send data into Twilio Segment",
        url: "/docs/connections/sources/"
      }, {
        title: "Destinations",
        description: "Tools or apps that Twilio Segment sends data to",
        url: "/docs/connections/destinations/"
      }, {
        title: "Release Notes",
        description: "Latest feature releases, product updates and more",
        url: "/release-notes/"
      }, {
        title: "Trust Center",
        description: "Customer engagement built on trust",
        url: "".concat(basePath, "/trust-center/")
      }]
    }
  };
};

export default docsData;