/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ResourcesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M15.1428 4.42929H7.64481C5 4.42929 4.42935 4.62416 4.42935 7.64475V15.1427C3.03796 15.0742 2.28571 14.2585 2.28571 12.8089V4.62417C2.28571 2.99993 3 2.28566 4.62423 2.28564H12.809C14.2586 2.28564 15.0743 3.03791 15.1428 4.42929Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M17 6H7C6.44772 6 6 6.44772 6 7V17C6 17.5523 6.44772 18 7 18H17C17.5523 18 18 17.5523 18 17V7C18 6.44772 17.5523 6 17 6Z",
    fill: "none"
  })]
}), 'ResourcesIcon', 'Navigation');
export default ResourcesIcon;