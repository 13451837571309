/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PersonasIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M13.5 11.625C12.819 11.6251 12.1471 11.7811 11.5358 12.081C11.3497 12.1721 11.2728 12.3967 11.3639 12.5827C11.3692 12.5935 11.375 12.604 11.3813 12.6143C12.0309 13.67 12.3749 14.8854 12.375 16.125C12.375 16.3321 12.5429 16.5 12.75 16.5H17.625C17.8321 16.5 18 16.3321 18 16.125C18 13.6397 15.9853 11.625 13.5 11.625Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M15.4468 6.44876C16.5451 7.54712 16.5451 9.32789 15.4468 10.4262C14.3484 11.5246 12.5677 11.5246 11.4693 10.4262C10.3709 9.32789 10.3709 7.54712 11.4693 6.44876C12.5677 5.35041 14.3484 5.35041 15.4468 6.44876Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M8.14412 4.04343C9.53536 5.43467 9.53536 7.69032 8.14412 9.08156C6.75288 10.4728 4.49723 10.4728 3.10599 9.08156C1.71475 7.69032 1.71475 5.43467 3.10599 4.04343C4.49723 2.65219 6.75288 2.65219 8.14412 4.04343Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M11 16.625C11 13.5184 8.76143 11 6 11C3.23857 11 1 13.5184 1 16.625C1 16.8321 1.14924 17 1.33333 17H10.6667C10.8508 17 11 16.8321 11 16.625Z",
    fill: "none"
  })]
}), 'PersonasIcon', 'Navigation');
export default PersonasIcon;