/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var JavascriptIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M11.9165 6.66674C11.1341 6.66674 10.5 6.03179 10.5 5.25V1H3.41653C2.6341 1 2 1.63346 2 2.41674V16.5833C2 17.3651 2.6341 18 3.41653 18H14.75C15.5316 18 16.1665 17.3665 16.1665 16.5833V6.66674H11.9165ZM8.4787 14.0613L7.72751 14.8125L5.04087 12.1257C4.7642 11.8496 4.7642 11.4008 5.04087 11.1241L7.72751 8.4375L8.4787 9.18869L6.04239 11.625L8.4787 14.0613ZM13.3333 14.1042H9.79153V13.0417H13.3333V14.1042Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.47871 14.0613L7.72752 14.8125L5.04088 12.1257C4.76421 11.8496 4.76421 11.4008 5.04088 11.1241L7.72752 8.4375L8.47871 9.18869L6.0424 11.625L8.47871 14.0613ZM13.3333 14.1042H9.79154V13.0417H13.3333V14.1042Z",
    fill: "white"
  })]
}), 'JavascriptIcon', 'Navigation');
export default JavascriptIcon;