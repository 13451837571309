/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SdksIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M12.9798 7.2032C13.074 7.16027 13.1156 7.04907 13.0726 6.95484C13.0539 6.91369 13.0209 6.8807 12.9798 6.86195L5.66198 3.52595C5.56505 3.48289 5.45442 3.48289 5.35748 3.52595L2.49473 4.76795C2.39947 4.80854 2.35514 4.91867 2.39572 5.01394C2.4147 5.05849 2.45018 5.09397 2.49473 5.11295L10.2122 8.32895C10.3085 8.36926 10.4172 8.36763 10.5122 8.32445L12.9798 7.2032Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M17.5465 5.12512C17.6409 5.08264 17.6831 4.97164 17.6406 4.8772C17.6218 4.83539 17.5883 4.80192 17.5465 4.78312L10.3 1.63537C10.1087 1.55188 9.89129 1.55188 9.7 1.63537L7.285 2.68537C7.19035 2.72738 7.14768 2.83816 7.18969 2.93281C7.20855 2.97529 7.24252 3.00926 7.285 3.02812L14.56 6.34462C14.6586 6.38948 14.7719 6.38948 14.8705 6.34462L17.5465 5.12512Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M1.6345 5.97243C1.53889 5.93267 1.42914 5.97794 1.38938 6.07356C1.37987 6.09641 1.37498 6.12093 1.375 6.14568V14.5051V14.5052C1.375 14.8079 1.55695 15.0809 1.83631 15.1974L9.54998 18.4126C9.6456 18.4524 9.75534 18.4071 9.79511 18.3115C9.80461 18.2887 9.8095 18.2641 9.80948 18.2394V9.50493V9.50494C9.80933 9.42921 9.76382 9.36095 9.69398 9.33169L1.6345 5.97243Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M10.9375 18.2079C10.938 18.3119 11.0228 18.3957 11.1267 18.3952C11.1528 18.395 11.1785 18.3895 11.2022 18.3789L18.1855 15.2049C18.4531 15.0832 18.6249 14.8164 18.625 14.5224V6.16446V6.16445C18.6251 6.0609 18.5412 5.97689 18.4376 5.97681C18.411 5.97679 18.3846 5.98247 18.3602 5.99346L11.05 9.31746C10.9828 9.34742 10.9396 9.41416 10.9397 9.48771L10.9375 18.2079Z",
    fill: "none"
  })]
}), 'SdksIcon', 'Navigation');
export default SdksIcon;