/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EngineeringIcon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.4,
    d: "M13.3328 5.6672C13.3328 4.56263 14.2282 3.6672 15.3328 3.6672H16.6672C17.7718 3.6672 18.7052 2.65755 17.9523 1.84933C17.4654 1.32663 16.7709 1 16 1H2.666C1.194 1.0004 0 2.1944 0 3.6672V27.6676C0 29.1412 1.194 30.334 2.666 30.334H16C16.7707 30.334 17.465 30.0076 17.9519 29.4853C18.705 28.6773 17.7718 27.6676 16.6672 27.6676H15.3328C14.2282 27.6676 13.3328 26.7722 13.3328 25.6676V5.6672ZM10.6668 23.6676C10.6668 24.4039 10.0699 25.0008 9.3336 25.0008H3.9992C3.2629 25.0008 2.666 24.4039 2.666 23.6676C2.666 22.9313 3.26289 22.3344 3.9992 22.3344H9.3336C10.0699 22.3344 10.6668 22.9313 10.6668 23.6676ZM10.6668 18.3336C10.6668 19.0701 10.0697 19.6672 9.3332 19.6672H3.9996C3.26307 19.6672 2.666 19.0701 2.666 18.3336C2.666 17.5971 3.26307 17 3.9996 17H9.3332C10.0697 17 10.6668 17.5971 10.6668 18.3336ZM10.6668 13.0006C10.6668 13.737 10.0698 14.334 9.3334 14.334H3.9994C3.26298 14.334 2.666 13.737 2.666 13.0006C2.666 12.2642 3.26298 11.6672 3.9994 11.6672H9.3334C10.0698 11.6672 10.6668 12.2642 10.6668 13.0006ZM10.6668 7.667C10.6668 8.40364 10.0696 9.0008 9.333 9.0008H3.9998C3.26316 9.0008 2.666 8.40364 2.666 7.667C2.666 6.93036 3.26316 6.3332 3.9998 6.3332H9.333C10.0696 6.3332 10.6668 6.93036 10.6668 7.667Z",
    fill: "url(#EngineeringIcon_svg__paint0_linear)"
  }), _jsx("path", {
    d: "M28.132 5.29085C28.4924 5.10527 28.9007 5 29.3352 5C30.806 5 32 6.1952 32 7.6672C32 9.1416 30.806 10.3344 29.3352 10.3348C28.9013 10.3348 28.4935 10.2303 28.1335 10.0455C27.9541 9.95335 27.775 9.85328 27.5953 9.75277C26.9283 9.37991 26.2509 9.0012 25.5025 9.0012H17.3338C16.5972 9.0012 16 8.40404 16 7.6674C16 6.93076 16.5972 6.3336 17.3338 6.3336H25.5049C26.2544 6.3336 26.9328 5.954 27.6005 5.58037C27.7781 5.48102 27.9549 5.3821 28.132 5.29085Z",
    fill: "url(#EngineeringIcon_svg__paint1_linear)"
  }), _jsx("path", {
    d: "M28.1327 15.9579C28.4928 15.7728 28.9009 15.668 29.3352 15.668C30.806 15.668 32 16.8636 32 18.334C32 19.8084 30.806 21.0012 29.3352 21.0012C28.9013 21.0012 28.4935 20.8966 28.1335 20.7118C27.954 20.6197 27.775 20.5196 27.5952 20.4191C26.9283 20.0463 26.2509 19.6676 25.5025 19.6676H17.3336C16.5971 19.6676 16 19.0705 16 18.334C16 17.5974 16.5971 17.0004 17.3336 17.0004H25.5045C26.253 17.0004 26.9307 16.6216 27.5978 16.2488C27.7764 16.1489 27.9544 16.0495 28.1327 15.9579Z",
    fill: "url(#EngineeringIcon_svg__paint2_linear)"
  }), _jsx("path", {
    d: "M21.3344 21.0013C20.7472 21.0013 20.2084 21.1932 19.7708 21.516C19.2188 21.9231 18.6147 22.3349 17.9289 22.3349H17.3332C16.5969 22.3349 16 22.9318 16 23.6681C16 24.4044 16.5969 25.0013 17.3332 25.0013H17.9282C18.6144 25.0013 19.2186 25.4138 19.7709 25.821C20.2085 26.1436 20.7473 26.3349 21.3344 26.3349C22.8064 26.3349 24.0008 25.1421 24.0008 23.6677C24.0008 22.1965 22.8064 21.0013 21.3344 21.0013Z",
    fill: "url(#EngineeringIcon_svg__paint3_linear)"
  }), _jsx("path", {
    d: "M22.7988 10.6241C23.1591 10.4389 23.5672 10.334 24.0016 10.334C25.4728 10.334 26.6668 11.5296 26.6668 13C26.6668 14.4744 25.4728 15.6672 24.0016 15.6672C23.5677 15.6672 23.1599 15.5627 22.7999 15.3779C22.6204 15.2857 22.4414 15.1857 22.2616 15.0851C21.5947 14.7123 20.9173 14.3336 20.1689 14.3336H17.3336C16.5971 14.3336 16 13.7365 16 13C16 12.2635 16.5971 11.6664 17.3336 11.6664H20.1713C20.92 11.6664 21.5979 11.2875 22.2651 10.9145C22.4434 10.8148 22.6209 10.7156 22.7988 10.6241Z",
    fill: "url(#EngineeringIcon_svg__paint4_linear)"
  }), _jsxs("defs", {
    children: [_jsxs("linearGradient", {
      id: "EngineeringIcon_svg__paint0_linear",
      x1: 18.6672,
      y1: 0.999999,
      x2: 20.3556,
      y2: 29.1114,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "EngineeringIcon_svg__paint1_linear",
      x1: 32,
      y1: 5,
      x2: 33.0431,
      y2: 25.4663,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "EngineeringIcon_svg__paint2_linear",
      x1: 32,
      y1: 5,
      x2: 33.0431,
      y2: 25.4663,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "EngineeringIcon_svg__paint3_linear",
      x1: 32,
      y1: 5,
      x2: 33.0431,
      y2: 25.4663,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "EngineeringIcon_svg__paint4_linear",
      x1: 32,
      y1: 5,
      x2: 33.0431,
      y2: 25.4663,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    })]
  })]
}), 'EngineeringIcon', 'Segment');
export default EngineeringIcon;