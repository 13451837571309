/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PrivacyIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M8.66665 2H3.306L3.306 2C2.58825 2.00485 2.00871 2.59869 2.008 3.33004L2 7.94306L2 7.94306C1.99984 8.0332 2.03486 8.1197 2.09733 8.18352C2.15986 8.24743 2.24477 8.28336 2.33333 8.28338H8.66665C8.85075 8.28338 8.99998 8.13132 8.99998 7.94374V2.33964C8.99998 2.15206 8.85075 2 8.66665 2V2Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M8.66676 10.321H2.42344V10.321C2.33139 10.3213 2.25696 10.3975 2.2572 10.4913C2.25723 10.504 2.25866 10.5166 2.26144 10.5289L2.26144 10.5289C3.03765 13.9849 5.49817 16.7943 8.78009 17.9718C8.8673 18.0018 8.96187 17.9541 8.99132 17.8653C8.99714 17.8477 9.0001 17.8293 9.00009 17.8108V10.6607C9.00009 10.4731 8.85085 10.321 8.66676 10.321L8.66676 10.321Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M17.5713 10.321H11.3333C11.1492 10.321 11 10.4731 11 10.6607V17.8299L11 17.8299C10.9999 17.9236 11.0745 17.9997 11.1665 17.9998C11.1847 17.9998 11.2028 17.9968 11.22 17.9908L11.22 17.9908C14.5049 16.8087 16.9643 13.9911 17.7333 10.5289V10.5289C17.7539 10.4375 17.6979 10.3464 17.6082 10.3254C17.5959 10.3225 17.5833 10.321 17.5706 10.321L17.5713 10.321Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M17.992 3.33004C17.9913 2.59869 17.4117 2.00486 16.694 2H11.3333C11.1492 2 11 2.15206 11 2.33964V7.94374C11 8.13132 11.1492 8.28338 11.3333 8.28338H17.6666C17.7552 8.28336 17.8401 8.24743 17.9026 8.18353C17.9651 8.11971 18.0001 8.0332 18 7.94306L17.992 3.33004Z",
    fill: "none"
  })]
}), 'PrivacyIcon', 'Navigation');
export default PrivacyIcon;