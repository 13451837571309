/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var B2BIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18 2H9C8.72386 2 8.5 2.22386 8.5 2.5V18C8.5 18.2762 8.72386 18.5 9 18.5H18C18.2761 18.5 18.5 18.2762 18.5 18V2.5C18.5 2.22386 18.2761 2 18 2ZM12.5 16C12.5 16.2762 12.2761 16.5 12 16.5H11C10.7239 16.5 10.5 16.2762 10.5 16V15C10.5 14.7239 10.7239 14.5 11 14.5H12C12.2761 14.5 12.5 14.7239 12.5 15V16ZM12 11.5C12.2761 11.5 12.5 11.2761 12.5 11V10C12.5 9.72386 12.2761 9.5 12 9.5H11C10.7239 9.5 10.5 9.72386 10.5 10V11C10.5 11.2761 10.7239 11.5 11 11.5H12ZM12.5 6C12.5 6.27614 12.2761 6.5 12 6.5H11C10.7239 6.5 10.5 6.27614 10.5 6V5C10.5 4.72386 10.7239 4.5 11 4.5H12C12.2761 4.5 12.5 4.72386 12.5 5V6ZM16 16.5C16.2761 16.5 16.5 16.2762 16.5 16V15C16.5 14.7239 16.2761 14.5 16 14.5H15C14.7239 14.5 14.5 14.7239 14.5 15V16C14.5 16.2762 14.7239 16.5 15 16.5H16ZM16.5 11C16.5 11.2761 16.2761 11.5 16 11.5H15C14.7239 11.5 14.5 11.2761 14.5 11V10C14.5 9.72386 14.7239 9.5 15 9.5H16C16.2761 9.5 16.5 9.72386 16.5 10V11ZM16 6.5C16.2761 6.5 16.5 6.27614 16.5 6V5C16.5 4.72386 16.2761 4.5 16 4.5H15C14.7239 4.5 14.5 4.72386 14.5 5V6C14.5 6.27614 14.7239 6.5 15 6.5H16Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M6.5 6H1.5C1.22386 6 1 6.22386 1 6.5V18C1 18.2761 1.22386 18.5 1.5 18.5H6.5C6.77614 18.5 7 18.2761 7 18V6.5C7 6.22386 6.77614 6 6.5 6Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12.5 16C12.5 16.2762 12.2761 16.5 12 16.5H11C10.7239 16.5 10.5 16.2762 10.5 16V15C10.5 14.7239 10.7239 14.5 11 14.5H12C12.2761 14.5 12.5 14.7239 12.5 15V16ZM12 11.5C12.2761 11.5 12.5 11.2761 12.5 11V10C12.5 9.72386 12.2761 9.5 12 9.5H11C10.7239 9.5 10.5 9.72386 10.5 10V11C10.5 11.2761 10.7239 11.5 11 11.5H12ZM12.5 6C12.5 6.27614 12.2761 6.5 12 6.5H11C10.7239 6.5 10.5 6.27614 10.5 6V5C10.5 4.72386 10.7239 4.5 11 4.5H12C12.2761 4.5 12.5 4.72386 12.5 5V6ZM16 16.5C16.2761 16.5 16.5 16.2762 16.5 16V15C16.5 14.7239 16.2761 14.5 16 14.5H15C14.7239 14.5 14.5 14.7239 14.5 15V16C14.5 16.2762 14.7239 16.5 15 16.5H16ZM16.5 11C16.5 11.2761 16.2761 11.5 16 11.5H15C14.7239 11.5 14.5 11.2761 14.5 11V10C14.5 9.72386 14.7239 9.5 15 9.5H16C16.2761 9.5 16.5 9.72386 16.5 10V11ZM16 6.5C16.2761 6.5 16.5 6.27614 16.5 6V5C16.5 4.72386 16.2761 4.5 16 4.5H15C14.7239 4.5 14.5 4.72386 14.5 5V6C14.5 6.27614 14.7239 6.5 15 6.5H16Z",
    fill: "white"
  })]
}), 'B2BIcon', 'Navigation');
export default B2BIcon;