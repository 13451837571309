/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var StartupIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    opacity: 0.24,
    children: [_jsx("path", {
      d: "M15.4329 10.7971L14.5493 16.1825C14.5233 16.3414 14.5753 16.5031 14.6891 16.6169L16.718 18.6463C16.9132 18.8416 17.2265 18.8397 17.4001 18.6249C17.9508 17.9437 19.0001 16.3967 19.0001 14.4999C19.0001 12.8908 16.3232 11.2863 15.4329 10.7971Z",
      fill: "none"
    }), _jsx("path", {
      d: "M2.60001 18.6252C2.77361 18.8399 3.08686 18.8419 3.28213 18.6466L5.33261 16.5961C5.44692 16.4818 5.49891 16.3193 5.47217 16.1599L4.68877 11.4894C4.63184 11.15 4.25813 10.9685 3.96465 11.1482C2.82697 11.8448 1 13.1709 1 14.5002C1 16.397 2.0494 17.944 2.60001 18.6252Z",
      fill: "none"
    }), _jsx("path", {
      d: "M7.92292 17.2942C8.01565 17.1551 8.17177 17.0715 8.33894 17.0715H11.6609C11.8281 17.0715 11.9842 17.1551 12.0769 17.2942L12.696 18.2228C12.9175 18.555 12.6793 19.0001 12.28 19.0001H7.71989C7.32055 19.0001 7.08235 18.5551 7.30387 18.2228L7.92292 17.2942Z",
      fill: "none"
    })]
  }), _jsx("path", {
    opacity: 0.48,
    d: "M9.99995 1C7.5 1 4.05995 3.88257 4.98373 9.37065L6.03019 15.5944C6.11116 16.076 6.52805 16.4286 7.01635 16.4286H13.0079C13.4977 16.4286 13.9153 16.0739 13.9947 15.5907L15.0162 9.37065C15.94 3.88193 12.4999 1 9.99995 1Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10Z",
    fill: "white"
  })]
}), 'StartupIcon', 'Navigation');
export default StartupIcon;