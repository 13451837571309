/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var HelpIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M10.0001 5.45313C10.6769 5.45313 11.3176 5.60073 11.8936 5.86713C12.1708 5.99313 12.4948 5.93913 12.7108 5.72673L14.7952 3.64233C15.1228 3.31473 15.058 2.76753 14.6656 2.52273C13.3156 1.66953 11.7136 1.17993 10.0001 1.17993C8.28645 1.17993 6.68445 1.66953 5.33085 2.51553C4.93845 2.76033 4.87365 3.30753 5.20125 3.63513L7.28565 5.71953C7.50165 5.93553 7.82565 5.98953 8.10285 5.85993C8.68245 5.60073 9.32325 5.45313 10.0001 5.45313Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M14.5468 9.99987C14.5468 10.6767 14.3992 11.3175 14.1328 11.8935C14.0068 12.1707 14.0608 12.4947 14.2732 12.7107L16.3576 14.7951C16.6852 15.1227 17.2324 15.0579 17.4772 14.6655C18.3304 13.3155 18.82 11.7135 18.82 9.99987C18.82 8.28627 18.3304 6.68427 17.4844 5.33067C17.2396 4.93827 16.6924 4.87347 16.3648 5.20107L14.2804 7.28547C14.0644 7.50147 14.0104 7.82547 14.14 8.10267C14.3992 8.68227 14.5468 9.32307 14.5468 9.99987Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M9.99999 14.5468C9.32319 14.5468 8.68239 14.3992 8.10639 14.1328C7.82919 14.0068 7.50519 14.0608 7.28919 14.2732L5.20479 16.3576C4.87719 16.6852 4.94199 17.2324 5.33439 17.4772C6.68439 18.3304 8.28639 18.82 9.99999 18.82C11.7136 18.82 13.3156 18.3304 14.6692 17.4844C15.0616 17.2396 15.1264 16.6924 14.7988 16.3648L12.7144 14.2804C12.4984 14.0644 12.1744 14.0104 11.8972 14.14C11.3176 14.3992 10.6768 14.5468 9.99999 14.5468Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M5.45319 9.99993C5.45319 9.32313 5.60079 8.68233 5.86719 8.10633C5.99319 7.82913 5.93919 7.50513 5.72679 7.28913L3.64239 5.20473C3.31479 4.87713 2.76759 4.94193 2.52279 5.33433C1.66959 6.68433 1.17999 8.28633 1.17999 9.99993C1.17999 11.7135 1.66959 13.3155 2.51559 14.6691C2.76039 15.0615 3.30759 15.1263 3.63519 14.7987L5.71959 12.7143C5.93559 12.4983 5.98959 12.1743 5.85999 11.8971C5.60079 11.3175 5.45319 10.6767 5.45319 9.99993Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M9.99994 13C11.6568 13 12.9999 11.6569 12.9999 10C12.9999 8.34315 11.6568 7 9.99994 7C8.34308 7 6.99994 8.34315 6.99994 10C6.99994 11.6569 8.34308 13 9.99994 13Z",
    fill: "none"
  })]
}), 'HelpIcon', 'Navigation');
export default HelpIcon;