/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ProductIcon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("rect", {
    width: 18.6667,
    height: 32,
    rx: 2,
    fill: "url(#ProductIcon_svg__paint0_linear)"
  }), _jsxs("g", {
    opacity: 0.4,
    children: [_jsx("path", {
      d: "M22.6666 2.66675H24.0001C24.7333 2.66675 25.3333 3.26675 25.3333 3.99995V27.9999C25.3333 28.7331 24.7333 29.3331 24.0001 29.3331H22.6666C21.9302 29.3331 21.3333 28.7362 21.3333 27.9998V4.00008C21.3333 3.2637 21.9302 2.66675 22.6666 2.66675Z",
      fill: "url(#ProductIcon_svg__paint1_linear)"
    }), _jsx("path", {
      d: "M29.3333 5.33341H30.6667C31.3995 5.33341 31.9999 5.93381 31.9999 6.66701V25.3334C31.9999 26.0666 31.3995 26.667 30.6667 26.667H29.3333C28.5969 26.667 27.9999 26.0701 27.9999 25.3337V6.66675C27.9999 5.93037 28.5969 5.33341 29.3333 5.33341Z",
      fill: "url(#ProductIcon_svg__paint2_linear)"
    })]
  }), _jsxs("defs", {
    children: [_jsxs("linearGradient", {
      id: "ProductIcon_svg__paint0_linear",
      x1: 18.6667,
      y1: -0.00000150015,
      x2: 20.6746,
      y2: 30.6454,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "ProductIcon_svg__paint1_linear",
      x1: 31.9999,
      y1: 2.66675,
      x2: 34.4283,
      y2: 28.0819,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    }), _jsxs("linearGradient", {
      id: "ProductIcon_svg__paint2_linear",
      x1: 31.9999,
      y1: 2.66675,
      x2: 34.4283,
      y2: 28.0819,
      gradientUnits: "userSpaceOnUse",
      children: [_jsx("stop", {
        stopColor: "#5E97EE"
      }), _jsx("stop", {
        offset: 1,
        stopColor: "#897AE3"
      })]
    })]
  })]
}), 'ProductIcon', 'Segment');
export default ProductIcon;