/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FunctionsIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M2 4C2 2.89543 2.89543 2 4 2H13V18H4C2.89543 18 2 17.1046 2 16V4Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M13 2H18V18H13V2Z",
    fill: "none"
  }), _jsx("path", {
    d: "M5.52775 4.42663C5.40032 4.65789 5.15723 4.80151 4.89325 4.80151C4.62927 4.80151 4.38618 4.65789 4.25875 4.42663C4.1867 4.3145 4.14675 4.18476 4.14325 4.05151C4.14664 3.91825 4.18659 3.78848 4.25875 3.6764C4.38618 3.44514 4.62927 3.30151 4.89325 3.30151C5.15723 3.30151 5.40032 3.44514 5.52775 3.6764C5.5999 3.78848 5.63986 3.91825 5.64325 4.05151C5.63975 4.18476 5.5998 4.3145 5.52775 4.42663Z",
    fill: "white"
  }), _jsx("path", {
    d: "M8.02775 4.42663C7.90032 4.65789 7.65723 4.80151 7.39325 4.80151C7.12927 4.80151 6.88618 4.65789 6.75875 4.42663C6.6867 4.3145 6.64675 4.18476 6.64325 4.05151C6.64664 3.91825 6.68659 3.78848 6.75875 3.6764C6.88618 3.44514 7.12927 3.30151 7.39325 3.30151C7.65723 3.30151 7.90032 3.44514 8.02775 3.6764C8.0999 3.78848 8.13986 3.91825 8.14325 4.05151C8.13975 4.18476 8.0998 4.3145 8.02775 4.42663Z",
    fill: "white"
  }), _jsx("path", {
    d: "M9.89325 4.80151C10.1572 4.80151 10.4003 4.65791 10.5277 4.42669C10.5998 4.31457 10.6397 4.18485 10.6432 4.05163C10.6425 3.9456 10.6189 3.84098 10.5742 3.74483C10.4539 3.47622 10.1876 3.30285 9.89325 3.30151C9.62996 3.30424 9.38811 3.4472 9.25875 3.67657C9.18659 3.78863 9.14664 3.91838 9.14325 4.05163C9.14675 4.18485 9.1867 4.31457 9.25875 4.42669C9.38618 4.65791 9.62927 4.80151 9.89325 4.80151Z",
    fill: "white"
  }), _jsx("path", {
    d: "M6.9952 11.4975C7.25556 11.7489 7.6776 11.7489 7.93795 11.4975L9.60478 9.88746C9.86506 9.63598 9.86506 9.22832 9.60478 8.97683L7.93795 7.36682C7.67634 7.12275 7.2605 7.12625 7.00332 7.37466C6.74614 7.62308 6.74253 8.02475 6.9952 8.27744L8.07263 9.31816C8.10398 9.34836 8.1216 9.38938 8.1216 9.43215C8.1216 9.47492 8.10398 9.51593 8.07263 9.54614L6.9952 10.5869C6.73492 10.8383 6.73492 11.246 6.9952 11.4975Z",
    fill: "white"
  }), _jsx("path", {
    d: "M14.1333 12.186H11.4667C11.0985 12.186 10.8 11.9622 10.8 11.686C10.8 11.4099 11.0985 11.186 11.4667 11.186H14.1333C14.5015 11.186 14.8 11.4099 14.8 11.686C14.8 11.9622 14.5015 12.186 14.1333 12.186Z",
    fill: "white"
  })]
}), 'FunctionsIcon', 'Navigation');
export default FunctionsIcon;