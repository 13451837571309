/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SegmentIcon = createIconsComponent(_jsxs("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    clipPath: "url(#SegmentIcon_svg__clip0_13_24679)",
    children: [_jsx("path", {
      d: "M23.3855 7.74414H9.67676C9.41572 7.74414 9.2041 7.94665 9.2041 8.19645V9.72444C9.2041 9.97425 9.41572 10.1768 9.67676 10.1768H23.3855C23.6466 10.1768 23.8582 9.97425 23.8582 9.72444V8.19645C23.8582 7.94665 23.6466 7.74414 23.3855 7.74414Z",
      fill: "#52BD94"
    }), _jsx("path", {
      d: "M14.3181 13.8235H0.609377C0.348335 13.8235 0.136719 14.026 0.136719 14.2758V15.8038C0.136719 16.0536 0.348335 16.2561 0.609377 16.2561H14.3181C14.5792 16.2561 14.7908 16.0536 14.7908 15.8038V14.2758C14.7908 14.026 14.5792 13.8235 14.3181 13.8235Z",
      fill: "#52BD94"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M1.89653 9.40928C2.01573 9.43882 2.14226 9.42306 2.24959 9.36529C2.35691 9.30753 2.43671 9.21225 2.47225 9.09943C3.22933 6.82417 4.84785 4.90192 7.01003 3.71009C9.17221 2.51825 11.7219 2.14294 14.1584 2.65783C14.2762 2.68129 14.3989 2.66051 14.5011 2.59978C14.6033 2.53906 14.6772 2.44303 14.7075 2.3316L15.1211 0.85442C15.1374 0.794993 15.141 0.733021 15.1316 0.672239C15.1222 0.611457 15.1 0.553126 15.0665 0.500762C15.0329 0.448399 14.9885 0.403088 14.9362 0.367561C14.8838 0.332035 14.8244 0.30703 14.7616 0.294053C11.6829 -0.3703 8.45567 0.100326 5.72349 1.61207C2.99131 3.12381 0.955007 5.56557 0.020701 8.45036C0.00286299 8.50821 -0.00264719 8.5689 0.00449764 8.62882C0.0116425 8.68875 0.0312966 8.74669 0.0622933 8.7992C0.0932899 8.85172 0.134997 8.89774 0.184938 8.93453C0.23488 8.97132 0.292037 8.99814 0.353017 9.01339L1.89653 9.40928Z",
      fill: "#52BD94"
    }), _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M22.1037 14.5907C21.9845 14.5612 21.858 14.577 21.7507 14.6347C21.6434 14.6925 21.5636 14.7877 21.5281 14.9006C20.771 17.1759 19.1525 19.0981 16.9903 20.29C14.8281 21.4818 12.2784 21.8571 9.84188 21.3422C9.7241 21.3188 9.60143 21.3396 9.49923 21.4003C9.39704 21.461 9.32315 21.557 9.29285 21.6684L8.87937 23.1456C8.86306 23.205 8.85949 23.267 8.86889 23.3278C8.87828 23.3886 8.90044 23.4469 8.93403 23.4992C8.96762 23.5516 9.01195 23.5969 9.06433 23.6325C9.11672 23.668 9.17608 23.693 9.23884 23.706C12.3175 24.3703 15.5448 23.8997 18.277 22.3879C21.0092 20.8762 23.0455 18.4344 23.9798 15.5496C23.9976 15.4918 24.0031 15.4311 23.996 15.3712C23.9889 15.3113 23.9692 15.2533 23.9382 15.2008C23.9072 15.1483 23.8655 15.1023 23.8155 15.0655C23.7656 15.0287 23.7084 15.0019 23.6475 14.9866L22.1037 14.5907Z",
      fill: "#52BD94"
    }), _jsx("path", {
      d: "M19.2434 5.07315C20.0131 5.07315 20.6371 4.47601 20.6371 3.73939C20.6371 3.00278 20.0131 2.40564 19.2434 2.40564C18.4736 2.40564 17.8496 3.00278 17.8496 3.73939C17.8496 4.47601 18.4736 5.07315 19.2434 5.07315Z",
      fill: "#52BD94"
    }), _jsx("path", {
      d: "M4.75654 21.5946C5.52628 21.5946 6.15028 20.9975 6.15028 20.2609C6.15028 19.5243 5.52628 18.9271 4.75654 18.9271C3.98679 18.9271 3.36279 19.5243 3.36279 20.2609C3.36279 20.9975 3.98679 21.5946 4.75654 21.5946Z",
      fill: "#52BD94"
    })]
  }), _jsx("defs", {
    children: _jsx("clipPath", {
      id: "SegmentIcon_svg__clip0_13_24679",
      children: _jsx("rect", {
        width: 24,
        height: 24,
        fill: "white"
      })
    })
  })]
}), 'SegmentIcon', 'Segment');
export default SegmentIcon;