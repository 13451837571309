/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var MediaIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.14,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M17.4857 4.08571C17.5993 4.08571 17.6914 3.99361 17.6914 3.88V3.26286C17.6914 2.92202 17.4151 2.64571 17.0743 2.64571H6.37715L6.22903 2.58318L5.12476 1.44023C4.85321 1.15888 4.47898 0.999978 4.08796 1H1.67534C0.750265 1.00045 0.000452811 1.75026 2.39123e-07 2.67534V13.1372L1.75254e-07 13.1373C-0.000387859 13.9321 0.643639 14.5768 1.43848 14.5772C1.43903 14.5772 1.43958 14.5772 1.44012 14.5772H1.64584C1.75945 14.5772 1.85155 14.4851 1.85155 14.3715V6.55428C1.85155 5.19093 2.95677 4.08571 4.32013 4.08571H17.4857Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.7315 5.52563H17.8972C18.6921 5.52654 19.3363 6.17071 19.3372 6.96563V16.8399C19.3363 17.6348 18.6921 18.279 17.8972 18.2799H4.73151C3.9366 18.279 3.29241 17.6348 3.2915 16.8399V6.96564C3.29241 6.17072 3.93659 5.52654 4.7315 5.52563ZM10.2611 15.0658L13.964 12.9026H13.9631C14.1022 12.8222 14.2177 12.7067 14.2981 12.5676C14.5514 12.1293 14.4015 11.5686 13.9631 11.3153L10.2603 9.15197C9.97174 8.98427 9.61645 8.9799 9.32387 9.14046C9.03038 9.30219 8.84745 9.61011 8.84579 9.9452V14.2718C8.84777 14.6075 9.03177 14.9156 9.32634 15.0765C9.61823 15.2372 9.97297 15.2332 10.2611 15.0658Z",
    fill: "none"
  }), _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.2611 15.0657L13.964 12.9025C14.1031 12.8221 14.2177 12.7066 14.2981 12.5675C14.5514 12.1292 14.4015 11.5685 13.9631 11.3152L10.2603 9.15188C9.97174 8.98418 9.61645 8.9798 9.32387 9.14036C9.03038 9.30209 8.84745 9.61002 8.84579 9.94511V14.2717C8.84777 14.6074 9.03177 14.9155 9.32634 15.0764C9.61823 15.2371 9.97297 15.2331 10.2611 15.0657Z",
    fill: "white"
  })]
}), 'MediaIcon', 'Navigation');
export default MediaIcon;