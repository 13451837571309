/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var FinanceIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M8.03424 10.5557H2V14.1112C2 14.6021 2.35815 15.0001 2.80005 15.0001H17.2C17.6418 15.0001 18 14.6021 18 14.1112V10.5557H11.9658C11.792 11.947 10.978 13 10 13C9.022 13 8.20796 11.947 8.03424 10.5557Z",
    fill: "#92889D"
  }), _jsx("path", {
    d: "M2.80005 5C2.35815 5 2 5.39798 2 5.88888V9.44444H8.03423C8.20792 8.05302 9.02198 7 10 7C10.978 7 11.7921 8.05302 11.9658 9.44444H18V5.88888C18 5.39798 17.6418 5 17.2 5H2.80005Z",
    fill: "#C9C3CE"
  }), _jsx("ellipse", {
    cx: 10,
    cy: 10,
    rx: 2,
    ry: 3,
    fill: "white"
  })]
}), 'FinanceIcon', 'Navigation');
export default FinanceIcon;