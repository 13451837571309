/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var LockIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M15.625 8.125H15.0625V6.0625C15.0625 3.26656 12.7959 1 10 1C7.20406 1 4.9375 3.26656 4.9375 6.0625V8.125H4.375C3.54657 8.125 2.875 8.79657 2.875 9.625V17.5C2.875 18.3284 3.54657 19 4.375 19H15.625C16.4534 19 17.125 18.3284 17.125 17.5V9.625C17.125 8.79657 16.4534 8.125 15.625 8.125ZM8.5 12.625C8.49626 11.7966 9.16479 11.122 9.99321 11.1182C10.8216 11.1145 11.4962 11.783 11.5 12.6114C11.5024 13.1498 11.2162 13.6481 10.75 13.9173V15.625C10.75 16.0392 10.4142 16.375 9.99999 16.375C9.58578 16.375 9.24999 16.0392 9.24999 15.625V13.9173V13.9172C8.78731 13.6512 8.5015 13.1587 8.5 12.625ZM6.8125 6.0625C6.8125 4.30209 8.23959 2.875 10 2.875C11.7604 2.875 13.1875 4.30209 13.1875 6.0625V7.75C13.1875 7.95711 13.0196 8.125 12.8125 8.125H7.1875C6.98039 8.125 6.8125 7.95711 6.8125 7.75V6.0625Z",
    fill: "none"
  }), _jsx("path", {
    d: "M8.5 12.625C8.49626 11.7966 9.16479 11.122 9.99321 11.1182C10.8216 11.1145 11.4962 11.783 11.5 12.6114C11.5024 13.1498 11.2162 13.6481 10.75 13.9173V15.625C10.75 16.0392 10.4142 16.375 9.99999 16.375C9.58578 16.375 9.24999 16.0392 9.24999 15.625V13.9173C8.78731 13.6513 8.5015 13.1587 8.5 12.625Z",
    fill: "white"
  })]
}), 'LockIcon', 'Navigation');
export default LockIcon;