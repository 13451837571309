/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var DestinationsIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M8.125 10V9.99998C8.12607 9.60196 7.9678 9.22006 7.68549 8.93949L6.56049 7.81449C6.27856 7.53421 5.89754 7.37631 5.5 7.375H2.125C1.50368 7.375 1 7.87868 1 8.5V11.5C1 12.1213 1.50368 12.625 2.125 12.625H5.5C5.89803 12.6261 6.27992 12.4678 6.5605 12.1855L7.6855 11.0605C7.96781 10.7799 8.12608 10.398 8.125 10Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M17.875 7.37501H14.5C14.102 7.37393 13.7201 7.5322 13.4395 7.81451L12.3145 8.93951C11.7289 9.52526 11.7289 10.4748 12.3145 11.0605L13.4395 12.1855C13.7201 12.4678 14.102 12.6261 14.5 12.625H17.875C18.4963 12.625 19 12.1213 19 11.5V8.5C19 7.87868 18.4963 7.375 17.875 7.375V7.37501Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M11.0605 12.313C10.4748 11.7274 9.52525 11.7274 8.9395 12.313L7.8145 13.438C7.53225 13.7192 7.37402 14.1015 7.375 14.5V17.875C7.375 18.4963 7.87868 19 8.5 19H11.5C12.1213 19 12.625 18.4963 12.625 17.875V14.5C12.6257 14.1022 12.4678 13.7205 12.1862 13.4395L11.0605 12.313Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M8.9395 7.684C9.52525 8.26957 10.4748 8.26957 11.0605 7.684L12.1855 6.559C12.467 6.2785 12.6252 5.8974 12.625 5.5V2.125C12.625 1.50368 12.1213 1 11.5 1H8.50001C7.87868 1 7.375 1.50368 7.375 2.125V5.5V5.49999C7.37397 5.89823 7.53221 6.28034 7.81449 6.56124L8.9395 7.684Z",
    fill: "none"
  })]
}), 'DestinationsIcon', 'Navigation');
export default DestinationsIcon;