import Media from '@segment/matcha/components/Media';
import MobileNavigation from './MobileNavigation';
import DesktopNavigation from './DesktopNavigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export var Navigation = function Navigation(_ref) {
  var announcement = _ref.announcement,
      mobileAnnouncement = _ref.mobileAnnouncement,
      after = _ref.after;
  return _jsxs(_Fragment, {
    children: [_jsxs(Media, {
      lessThan: "lg",
      children: [_jsx(MobileNavigation, {
        announcement: mobileAnnouncement || announcement
      }), after]
    }), _jsxs(Media, {
      greaterThanOrEqual: "lg",
      children: [_jsx(DesktopNavigation, {
        announcement: announcement
      }), after]
    })]
  });
};
export default Navigation;