/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CustomerStoriesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M8.12499 10.375C8.12499 9.13235 9.13235 8.12499 10.375 8.12499H16.1875C16.291 8.12499 16.375 8.04105 16.375 7.93749V2.125C16.375 1.50368 15.8713 1 15.25 1H2.125C1.50368 1 1 1.50368 1 2.125V12.9999C1 13.6213 1.50368 14.1249 2.125 14.1249H4.375V16.7499C4.375 16.9016 4.46646 17.0384 4.60669 17.0964C4.65217 17.1151 4.70083 17.1248 4.75 17.1249C4.84963 17.1249 4.94516 17.0853 5.0155 17.0147L7.90524 14.1249H7.93749C8.04104 14.1249 8.12499 14.041 8.12499 13.9374V10.375ZM3.2275 8.47674C3.44728 8.25781 3.80271 8.25781 4.0225 8.47674C4.6353 9.09574 5.43768 9.49179 6.30174 9.60174C6.60992 9.6413 6.82768 9.92319 6.78812 10.2314C6.74856 10.5395 6.46667 10.7573 6.15849 10.7177C5.04761 10.5765 4.01587 10.0678 3.2275 9.27249C3.00776 9.05289 3.00764 8.69674 3.22724 8.477C3.22733 8.47691 3.22741 8.47683 3.2275 8.47674ZM6.81249 5.875C6.81249 6.18566 6.56065 6.43749 6.24999 6.43749C5.93933 6.43749 5.68749 6.18566 5.68749 5.875V4.375C5.68749 4.06434 5.93933 3.8125 6.24999 3.8125C6.56065 3.8125 6.81249 4.06434 6.81249 4.375V5.875ZM11.3125 5.875C11.3125 6.18566 11.0606 6.43749 10.75 6.43749C10.4393 6.43749 10.1875 6.18566 10.1875 5.875V4.375C10.1875 4.06434 10.4393 3.8125 10.75 3.8125C11.0606 3.8125 11.3125 4.06434 11.3125 4.375V5.875Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M17.875 9.25H10.375C9.75368 9.25 9.25 9.75368 9.25 10.375V15.625C9.25 16.2463 9.75368 16.75 10.375 16.75H13.2497L16.15 18.9249C16.3157 19.0492 16.5507 19.0156 16.675 18.85C16.7237 18.785 16.75 18.7061 16.75 18.6249V16.75H17.875C18.4963 16.75 19 16.2463 19 15.625V10.375C19 9.75368 18.4963 9.25 17.875 9.25ZM11.875 13C11.875 12.5857 12.2108 12.25 12.625 12.25C13.0392 12.25 13.375 12.5857 13.375 13C13.375 13.4142 13.0392 13.75 12.625 13.75C12.2108 13.75 11.875 13.4142 11.875 13ZM15.625 13.75C15.2108 13.75 14.875 13.4142 14.875 13C14.875 12.5857 15.2108 12.25 15.625 12.25C16.0392 12.25 16.375 12.5857 16.375 13C16.375 13.4142 16.0392 13.75 15.625 13.75Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.0225 8.47674C3.80271 8.25781 3.44728 8.25781 3.2275 8.47674C3.0079 8.69648 3.00776 9.05289 3.2275 9.27249C4.01587 10.0678 5.04761 10.5765 6.15849 10.7177C6.46667 10.7573 6.74856 10.5395 6.78812 10.2314C6.82768 9.92319 6.60992 9.6413 6.30174 9.60174C5.43768 9.49179 4.6353 9.09574 4.0225 8.47674Z",
    fill: "white"
  }), _jsx("path", {
    d: "M6.24999 6.43749C6.56065 6.43749 6.81249 6.18566 6.81249 5.875V4.375C6.81249 4.06434 6.56065 3.8125 6.24999 3.8125C5.93933 3.8125 5.68749 4.06434 5.68749 4.375V5.875C5.68749 6.18566 5.93933 6.43749 6.24999 6.43749Z",
    fill: "white"
  }), _jsx("path", {
    d: "M10.75 6.43749C11.0606 6.43749 11.3125 6.18566 11.3125 5.875V4.375C11.3125 4.06434 11.0606 3.8125 10.75 3.8125C10.4393 3.8125 10.1875 4.06434 10.1875 4.375V5.875C10.1875 6.18566 10.4393 6.43749 10.75 6.43749Z",
    fill: "white"
  }), _jsx("path", {
    d: "M12.625 12.25C12.2108 12.25 11.875 12.5857 11.875 13C11.875 13.4142 12.2108 13.75 12.625 13.75C13.0392 13.75 13.375 13.4142 13.375 13C13.375 12.5857 13.0392 12.25 12.625 12.25Z",
    fill: "white"
  }), _jsx("path", {
    d: "M14.875 13C14.875 13.4142 15.2108 13.75 15.625 13.75C16.0392 13.75 16.375 13.4142 16.375 13C16.375 12.5857 16.0392 12.25 15.625 12.25C15.2108 12.25 14.875 12.5857 14.875 13Z",
    fill: "white"
  })]
}), 'CustomerStoriesIcon', 'Navigation');
export default CustomerStoriesIcon;