/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PrivacyTermIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M9.2 1L2.62388 3.4233C1.94016 3.67432 1.49948 4.23601 1.5 4.85581V6.92238C1.51133 11.3855 4.00226 15.5955 8.25278 18.3355L9.2 18.9686V1Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M10.8 18.9999L11.7464 18.3673C16.0076 15.6207 18.5 11.3964 18.5 6.92229V4.85571C18.5005 4.2359 18.0598 3.67421 17.3761 3.4232L10.8 1.00317V18.9999Z",
    fill: "none"
  })]
}), 'PrivacyTermIcon', 'Navigation');
export default PrivacyTermIcon;