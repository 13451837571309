var resourcesData = function resourcesData(_ref) {
  var basePath = _ref.basePath;
  return {
    content: {
      title: "Resources",
      links: [{
        title: "Learn With Segment",
        id: "learn-with-segment"
      }, {
        title: "Get Started With Segment",
        id: "get-started-with-segment"
      }, {
        title: "Support",
        id: "support"
      }, {
        title: "Partners",
        id: "partners"
      }]
    },
    aside: [{
      title: "Learn With Segment",
      id: "learn-with-segment",
      links: [{
        title: "Resources",
        description: "Guides and books for your customer data journey",
        url: "".concat(basePath, "/resources/")
      }, {
        title: "Blog",
        description: "The latest on our product, industry and customer data practice",
        url: "https://segment.com/blog/"
      }, {
        title: "Segment Community",
        description: "Connect with peers, share tips, get answers, and stay informed",
        url: "https://community.segment.com/"
      }, {
        title: "Growth Center",
        description: "Learn to implement high-performing growth strategies",
        url: "".concat(basePath, "/growth-center/")
      }, {
        title: "Data Hub",
        description: "Enhance your customer data management skills",
        url: "".concat(basePath, "/data-hub/")
      }, {
        title: "Case Studies",
        description: "Learn about the ways our customers use Segment",
        url: "".concat(basePath, "/customers/")
      }]
    }, {
      title: "Get Started With Segment",
      id: "get-started-with-segment",
      links: [{
        title: "Segment University",
        description: "Learn to implement and use Twilio Segment successfully",
        url: "https://university.segment.com/"
      }, {
        title: "Integrations",
        description: "Explore 450+ integrations to connect your tools",
        url: "".concat(basePath, "/catalog/")
      }, {
        title: "Professional Services",
        description: "Get expert guidance on your Twilio Segment journey",
        url: "".concat(basePath, "/services/")
      }, {
        title: "Recipes",
        description: "Step by step tutorials on using Segment integrations to achieve your goals",
        url: "".concat(basePath, "/recipes/")
      }, {
        title: "Docs",
        description: "Technical documentation for all things Twilio Segment",
        url: "/docs/"
      }]
    }, {
      title: "Support",
      id: "support",
      links: [{
        title: "Contact Us",
        description: "Connect with our support team for additional help",
        url: "".concat(basePath, "/contact/")
      }, {
        title: "Support Plans",
        description: "Dedicated support from our team of experts",
        url: "".concat(basePath, "/support-plans/")
      }]
    }, {
      title: "Partners",
      id: "partners",
      links: [{
        title: "Find a Partner",
        description: "Find a trusted solution partner",
        url: "https://segment.partnerpage.io/"
      }, {
        title: "Become a Partner",
        description: "Get your software and services in front of our user base",
        url: "".concat(basePath, "/partners/")
      }, {
        title: "Build on Twilio Segment",
        description: "Build an integration between your tool and Twilio Segment",
        url: "".concat(basePath, "/partners/developer-portal/")
      }]
    }]
  };
};

export default resourcesData;