/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var BuildIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsxs("g", {
    opacity: 0.24,
    children: [_jsx("path", {
      d: "M8.51103 5.86532L9.14863 6.50292C9.29505 6.64938 9.29505 6.8868 9.14863 7.03326L7.03028 9.15161C6.88382 9.29803 6.6464 9.29803 6.49994 9.15161L5.86084 8.51251C5.77015 8.42459 5.6413 8.38821 5.51803 8.41574C4.86862 8.55261 4.19451 8.51446 3.56469 8.3052C1.60169 7.65299 0.539088 5.53293 1.19131 3.56994C1.20948 3.51403 1.24055 3.46318 1.28199 3.42149C1.42803 3.27458 1.6655 3.27388 1.81241 3.41991L3.64947 5.25622C3.71964 5.32661 3.81487 5.36627 3.91426 5.36649H4.97494C5.18208 5.36649 5.35 5.19856 5.35 4.99142V3.93075C5.34998 3.83147 5.3106 3.73624 5.24048 3.66595L3.39818 1.82215C3.35722 1.78153 3.32619 1.73201 3.30749 1.67744C3.24033 1.48149 3.34475 1.2682 3.5407 1.20104C4.18031 0.982556 4.86713 0.941258 5.52831 1.08153C7.55138 1.51073 8.84346 3.49869 8.41426 5.52176C8.38647 5.64527 8.42286 5.77447 8.51103 5.86532Z",
      fill: "none"
    }), _jsx("path", {
      d: "M13.4985 10.853L14.1332 11.4876C14.2247 11.5734 14.3531 11.6078 14.4752 11.5791C15.1375 11.4385 15.8255 11.48 16.4661 11.6993C18.4227 12.3692 19.4658 14.4983 18.7959 16.4549C18.7774 16.5094 18.7467 16.5589 18.706 16.5996C18.5595 16.7461 18.3221 16.7462 18.1756 16.5997L16.3355 14.7597C16.2652 14.6891 16.1696 14.6494 16.07 14.6494H15.0101C14.8029 14.6494 14.635 14.8173 14.635 15.0245V16.0829C14.6348 16.1824 14.6742 16.278 14.7445 16.3484L16.5816 18.1855C16.623 18.2272 16.6541 18.278 16.6722 18.3339C16.7363 18.5309 16.6285 18.7425 16.4315 18.8066C16.0467 18.9346 15.6437 19 15.2381 19.0001C14.9819 18.9994 14.7264 18.9724 14.4757 18.9196C12.4512 18.4929 11.156 16.5058 11.5827 14.4814C11.6104 14.3582 11.5744 14.2294 11.4867 14.1386L10.8499 13.5017C10.7035 13.3552 10.7035 13.1178 10.8499 12.9714L12.9682 10.853C13.1147 10.7066 13.3521 10.7066 13.4985 10.853Z",
      fill: "none"
    })]
  }), _jsxs("g", {
    opacity: 0.48,
    children: [_jsx("path", {
      d: "M16.7061 8.20186C16.3062 8.60012 15.6596 8.60012 15.2598 8.20186L10.4493 3.39281C10.0502 2.99333 10.0502 2.34606 10.4493 1.94657L11.2406 1.15669C11.3894 1.0158 11.6223 1.0158 11.771 1.15669C12.6643 2.12954 14.1057 2.36842 15.2651 1.73578C15.4132 1.64295 15.6058 1.66473 15.7294 1.78829L18.7014 4.76029C19.1005 5.15978 19.1005 5.80704 18.7014 6.20653L16.7061 8.20186Z",
      fill: "none"
    }), _jsx("path", {
      d: "M11.7867 6.51631L13.485 8.21459C13.6314 8.36105 13.6314 8.59847 13.485 8.74493L4.04323 18.1875C3.45723 18.7732 2.50689 18.7735 1.92112 18.1875L1.81386 18.0803C1.22819 17.4944 1.22819 16.5447 1.81386 15.9589L11.2564 6.51631C11.4029 6.36989 11.6403 6.36989 11.7867 6.51631Z",
      fill: "none"
    })]
  })]
}), 'BuildIcon', 'Navigation');
export default BuildIcon;