/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var PartnersIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M18.9336 6.26719C18.7802 5.85309 18.386 5.57755 17.9444 5.57569H14.3766C14.2179 5.5756 14.0764 5.47557 14.0234 5.32594L12.7371 1.68469C12.5326 1.13897 11.9244 0.862381 11.3786 1.06692C11.0931 1.17393 10.8679 1.39919 10.7609 1.68469L10.7572 1.69894L9.47466 5.32894C9.42155 5.47883 9.27968 5.57892 9.12066 5.57869H5.55366C4.97086 5.57944 4.49902 6.0525 4.49976 6.63529C4.50016 6.94763 4.63891 7.24375 4.87866 7.44394L7.93266 9.97519C8.0517 10.074 8.09786 10.2357 8.04891 10.3824L7.74966 11.2757C7.68822 11.4593 7.77661 11.6594 7.95366 11.7377C8.60379 12.024 9.12072 12.5469 9.39966 13.2002C9.47861 13.3827 9.52774 13.5766 9.54516 13.7747C9.5635 13.981 9.7456 14.1334 9.95189 14.115C10.0152 14.1094 10.076 14.0878 10.1287 14.0522L11.5311 13.1042C11.6622 13.0157 11.8347 13.019 11.9624 13.1124L15.1124 15.4194C15.5814 15.7654 16.2421 15.6655 16.588 15.1965C16.7917 14.9203 16.8483 14.5623 16.7399 14.2367L15.4551 10.3824C15.4062 10.2357 15.4523 10.074 15.5714 9.97519L18.6344 7.43719C18.9687 7.14798 19.088 6.68142 18.9336 6.26719Z",
    fill: "none"
  }), _jsxs("g", {
    opacity: 0.24,
    children: [_jsx("path", {
      d: "M1.74963 12.063C2.16049 12.071 2.49165 12.4021 2.49963 12.813V18.2505C2.48281 18.6575 2.15667 18.9837 1.74963 19.0005C1.33542 19.0005 0.999634 18.6647 0.999634 18.2505V12.813C0.999634 12.3988 1.33542 12.063 1.74963 12.063Z",
      fill: "none"
    }), _jsx("path", {
      d: "M5.31213 15.1568C5.31213 15.4156 5.522 15.6255 5.78088 15.6255H12.6246C13.0388 15.6255 13.3746 15.9613 13.3746 16.3755V16.5735C13.3738 17.4993 12.6234 18.2497 11.6976 18.2505H3.62463C3.41752 18.2505 3.24963 18.0826 3.24963 17.8755V13.0005C3.24963 12.7934 3.41752 12.6255 3.62463 12.6255H6.82263C7.49375 12.6251 8.1003 13.0253 8.36388 13.6425C8.4039 13.7359 8.42454 13.8364 8.42455 13.9379C8.42459 14.3521 8.08884 14.688 7.67463 14.688H5.78088C5.522 14.688 5.31213 14.8979 5.31213 15.1568Z",
      fill: "none"
    })]
  })]
}), 'PartnersIcon', 'Navigation');
export default PartnersIcon;