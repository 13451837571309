var resourcesData = function resourcesData(_ref) {
  var basePath = _ref.basePath;
  return {
    content: {
      title: "Solutions",
      links: [{
        title: "Use Cases",
        description: "Build solutions to scale your business",
        id: "use-cases"
      }, {
        title: "Teams",
        description: "Empower all teams with customer data",
        id: "teams"
      }, {
        title: "Industry",
        description: "Customer data solutions that meet your industry\u2019s needs",
        id: "industry"
      }, {
        title: "Company Size",
        description: "Data solutions that scale as you grow",
        id: "company-size"
      }],
      additionalLinks: {
        title: "More Solutions",
        links: [{
          title: "CustomerAI",
          description: "Turn every customer interaction into a unique customer experience",
          url: "".concat(basePath, "/solutions/ai/")
        }, {
          title: "Case Studies",
          description: "Learn about the ways our customers use Segment",
          url: "".concat(basePath, "/customers/")
        }, {
          title: "Recipes",
          description: "Step by step tutorials on using Segment integrations to achieve your goals",
          url: "".concat(basePath, "/recipes/")
        }]
      }
    },
    aside: [{
      title: "Use Cases",
      id: "use-cases",
      links: [{
        title: "Use Cases Overview",
        description: "Empower ever team to solve key challenges",
        url: "".concat(basePath, "/use-cases/")
      }, {
        title: "Customer Data Management",
        description: "Collection, compliance, and activation",
        url: "".concat(basePath, "/use-cases/customer-data-management-integration/")
      }, {
        title: "Single View of the Customer",
        description: "Identity-resolved, enriched, and real-time profiles",
        url: "".concat(basePath, "/use-cases/single-customer-view/")
      }, {
        title: "Audience Segmentation & Activation",
        description: "Build targeted audiences and orchestrate journeys",
        url: "".concat(basePath, "/use-cases/segmentation-audience-activation/")
      }, {
        title: "Customer Acquisition",
        description: "Cost-effectively acquire new customers",
        url: "".concat(basePath, "/use-cases/customer-acquisition-activation/")
      }, {
        title: "Customer LTV & Retention",
        description: "Improve customer loyalty and engagement",
        url: "".concat(basePath, "/use-cases/retain-customers-increase-ltv/")
      }, {
        title: "Insights & Analytics Enablement",
        description: "Make data-driven decisions",
        url: "".concat(basePath, "/use-cases/customer-insights/")
      }]
    }, {
      title: "Twilio Segment For...",
      id: "teams",
      links: [{
        title: "Marketing Teams",
        description: "Personalize multichannel experiences",
        url: "".concat(basePath, "/marketing/")
      }, {
        title: "Product Teams",
        description: "Inform your roadmap with customer data",
        url: "".concat(basePath, "/product/")
      }, {
        title: "Engineering Teams",
        description: "Collect and manage your data in a single platform",
        url: "".concat(basePath, "/engineering/")
      }]
    }, {
      title: "Industry",
      id: "industry",
      links: [{
        title: "Retail",
        description: "Create personalized shopping experiences",
        url: "".concat(basePath, "/industry/retail/")
      }, {
        title: "B2B",
        description: "Unify your buyer journey and act on intent signals",
        url: "".concat(basePath, "/industry/b2b/")
      }, {
        title: "Financial Services",
        description: "Collect clean and compliant customer data",
        url: "".concat(basePath, "/industry/financial-services/")
      }, {
        title: "Healthcare",
        description: "Transform care with HIPAA-eligible data",
        url: "".concat(basePath, "/industry/healthcare/")
      }, {
        title: "Marketplaces",
        description: "Increase loyalty with data-driven personalization",
        url: "".concat(basePath, "/industry/marketplaces/")
      }, {
        title: "Media",
        description: "Increase engagement from your audience",
        url: "".concat(basePath, "/industry/media/")
      }]
    }, {
      title: "Company Size",
      id: "company-size",
      links: [{
        title: "Startup",
        description: "Early-stage startups can receive Twilio Segment for free",
        url: "".concat(basePath, "/industry/startups/")
      }, {
        title: "Enterprise",
        description: "Data clarity across your organization",
        url: "".concat(basePath, "/industry/enterprise/")
      }]
    }, {
      title: "Partners",
      id: "partners",
      links: [{
        title: "Find a Partner",
        description: "Find a trusted solution partner",
        url: "https://segment.partnerpage.io/"
      }, {
        title: "Become a Partner",
        description: "Get your software and services in front of our user base",
        url: "".concat(basePath, "/partners/")
      }, {
        title: "Build on Twilio Segment",
        description: "Build an integration between your tool and Twilio Segment",
        url: "".concat(basePath, "/partners/developer-portal/")
      }]
    }]
  };
};

export default resourcesData;