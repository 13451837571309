/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var AcademyIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M18.5476 6.17744L10.3658 2.08652C10.1358 1.97116 9.86421 1.97116 9.63348 2.08652L1.45164 6.17744C1.28718 6.26008 1.16118 6.40245 1.08755 6.55626C1.03764 6.65608 1 6.82545 1 6.90972V11.6265C1 12.0782 1.36655 12.4447 1.81818 12.4447C2.26982 12.4447 2.63637 12.0782 2.63637 11.6265V7.86454L10.0123 10.9376L18.4862 7.66981C18.7849 7.55036 18.9861 7.26645 18.9992 6.94408C19.0131 6.62172 18.8356 6.32226 18.5476 6.17744Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M10 12.2378C10 12.2378 9.77543 12.2066 9.67 12.1453L4 10V13.6207C4 14.0748 4.18343 14.4917 4.474 14.6947C5.944 15.7254 7.45857 17 10 17C12.5397 17 14.0586 15.723 15.526 14.6947C15.8166 14.4929 16 14.076 16 13.6219V10.2571L10.3077 12.1585C10.2083 12.2114 10 12.2378 10 12.2378Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    d: "M1.81818 13.4551C1.36655 13.4551 1 13.8216 1 14.2733V15.9097C1 16.3613 1.36655 16.7278 1.81818 16.7278C2.26982 16.7278 2.63637 16.3613 2.63637 15.9097V14.2733C2.63637 13.8216 2.26982 13.4551 1.81818 13.4551Z",
    fill: "none"
  })]
}), 'AcademyIcon', 'Navigation');
export default AcademyIcon;