/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var RetailIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M13.3328 5.99995C12.9648 5.99995 12.6749 5.69692 12.5841 5.3402C12.2904 4.18667 11.2445 3.33338 9.99981 3.33338C8.75501 3.33338 7.70913 4.18657 7.41537 5.34001C7.3245 5.69682 7.03456 5.99995 6.66636 5.99995C6.29815 5.99995 5.99384 5.69922 6.0545 5.33605C6.37075 3.44259 8.01694 2 9.99961 2C11.9823 2 13.6284 3.44269 13.9445 5.33625C14.0052 5.69931 13.7009 5.99995 13.3328 5.99995Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.9843 16.6848L15.8701 9.04488C15.7268 8.06219 14.8841 7.3335 13.891 7.3335H6.10887C5.11577 7.3335 4.2731 8.06221 4.1298 9.04491L3.01578 16.6848C2.9099 17.4074 3.34827 18 3.99034 18H16.0095C16.6511 18 17.0896 17.4074 16.9843 16.6848Z",
    fill: "none"
  })]
}), 'RetailIcon', 'Navigation');
export default RetailIcon;