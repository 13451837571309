/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ToolBoxIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.24,
    d: "M8.03125 9.43802C8.03125 9.12736 8.28309 8.87552 8.59375 8.87552H11.4062C11.7169 8.87552 11.9688 9.12736 11.9688 9.43802V9.62552C11.9688 9.83262 12.1366 10.0005 12.3438 10.0005H18.25C18.4571 10.0005 18.625 9.83262 18.625 9.62552V6.81302C18.625 6.3988 18.2892 6.06302 17.875 6.06302H13.7313C13.5244 4.0023 11.6862 2.49945 9.62547 2.70629C7.85063 2.88445 6.4469 4.28816 6.26875 6.06302H2.125C1.71079 6.06302 1.375 6.3988 1.375 6.81302V9.62552C1.375 9.83262 1.54289 10.0005 1.75 10.0005H7.65625C7.86336 10.0005 8.03125 9.83262 8.03125 9.62552V9.43802ZM10 4.18802C11.0979 4.18803 12.0355 4.98045 12.2185 6.06302H7.7815C7.96449 4.98045 8.90208 4.18803 10 4.18802Z",
    fill: "none"
  }), _jsxs("g", {
    opacity: 0.48,
    children: [_jsx("path", {
      d: "M9.53125 10.0012H10.4688C10.6759 10.0012 10.8438 10.1691 10.8438 10.3762V11.7825C10.8438 12.2485 10.466 12.6262 10 12.6262C9.53401 12.6262 9.15625 12.2485 9.15625 11.7825V10.3762C9.15625 10.1691 9.32414 10.0012 9.53125 10.0012Z",
      fill: "none"
    }), _jsx("path", {
      d: "M9.99475 13.752C11.0837 13.7533 11.9675 12.8715 11.9688 11.7826V11.5006C11.9687 11.4888 11.9692 11.477 11.9703 11.4653C11.9889 11.2591 12.1712 11.107 12.3775 11.1256H18.25C18.4571 11.1256 18.625 11.2935 18.625 11.5006V16.5631C18.625 16.9773 18.2892 17.3131 17.875 17.3131H2.125C1.71079 17.3131 1.375 16.9773 1.375 16.5631V11.5014C1.375 11.2942 1.54289 11.1264 1.75 11.1264H7.65704C7.86414 11.1264 8.03204 11.2942 8.03204 11.5014V11.7046C8.01592 12.6623 8.67308 13.5004 9.60704 13.7131C9.73467 13.7389 9.86454 13.7519 9.99475 13.752Z",
      fill: "none"
    })]
  })]
}), 'ToolBoxIcon', 'Navigation');
export default ToolBoxIcon;