/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApiReferenceIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5 0.806396H15C17.2091 0.806396 19 2.59726 19 4.8064C19 7.01554 17.2091 8.80639 15 8.80639H5C2.79086 8.80639 1 7.01554 1 4.8064C1 2.59726 2.79086 0.806396 5 0.806396ZM5.00001 6.80638C6.10458 6.80638 7.00001 5.91095 7.00001 4.80638C7.00001 3.70181 6.10458 2.80638 5.00001 2.80638C3.89544 2.80638 3.00001 3.70181 3.00001 4.80638C3.00001 5.91095 3.89544 6.80638 5.00001 6.80638Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M5 10.8064H15C17.2091 10.8064 19 12.5972 19 14.8064C19 17.0155 17.2091 18.8064 15 18.8064H5C2.79086 18.8064 1 17.0155 1 14.8064C1 12.5972 2.79086 10.8064 5 10.8064ZM15 16.8063C16.1046 16.8063 17 15.9109 17 14.8063C17 13.7018 16.1046 12.8063 15 12.8063C13.8954 12.8063 13 13.7018 13 14.8063C13 15.9109 13.8954 16.8063 15 16.8063Z",
    fill: "none"
  }), _jsx("path", {
    d: "M7 4.8064C7 5.91097 6.10457 6.8064 5 6.8064C3.89543 6.8064 3 5.91097 3 4.8064C3 3.70183 3.89543 2.8064 5 2.8064C6.10457 2.8064 7 3.70183 7 4.8064Z",
    fill: "white"
  }), _jsx("path", {
    d: "M17 14.8064C17 15.911 16.1046 16.8064 15 16.8064C13.8954 16.8064 13 15.911 13 14.8064C13 13.7019 13.8954 12.8064 15 12.8064C16.1046 12.8064 17 13.7019 17 14.8064Z",
    fill: "white"
  })]
}), 'ApiReferenceIcon', 'Navigation');
export default ApiReferenceIcon;