/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var SpecIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M17.875 1H2.125C1.50368 1 1 1.50368 1 2.125V12.4375C1 12.5411 1.08395 12.625 1.1875 12.625H18.8125C18.9161 12.625 19 12.5411 19 12.4375V2.125C19 1.50368 18.4963 1 17.875 1V1ZM4.5745 4.375H5.863C6.17366 4.375 6.4255 4.62684 6.4255 4.9375C6.4255 5.24816 6.17366 5.5 5.863 5.5H4.5745C4.26384 5.5 4.012 5.24816 4.012 4.9375C4.012 4.62684 4.26384 4.375 4.5745 4.375ZM8.512 9.625H4.5745C4.26384 9.625 4.012 9.37316 4.012 9.0625C4.012 8.75184 4.26384 8.5 4.5745 8.5H8.512C8.82266 8.5 9.0745 8.75184 9.0745 9.0625C9.0745 9.37316 8.82266 9.625 8.512 9.625ZM8.737 6.25C8.01302 6.28597 7.39696 5.72822 7.361 5.00424C7.32503 4.28025 7.88278 3.66419 8.60676 3.62823C9.33065 3.59227 9.94666 4.14987 9.98276 4.87375C10.0189 5.59643 9.46334 6.21204 8.74076 6.25H8.737ZM11.386 10.375C10.662 10.4114 10.0457 9.85398 10.0093 9.13002C9.97291 8.40606 10.5303 7.78968 11.2543 7.75331C11.9782 7.71693 12.5946 8.27432 12.631 8.99829C12.631 8.99844 12.631 8.9986 12.631 8.99875C12.6672 9.72115 12.112 10.3366 11.3898 10.375H11.386ZM15.4218 9.625H14.137C13.8263 9.625 13.5745 9.37316 13.5745 9.0625C13.5745 8.75184 13.8263 8.5 14.137 8.5H15.4255C15.7362 8.5 15.988 8.75184 15.988 9.0625C15.988 9.37316 15.7362 9.625 15.4255 9.625H15.4218ZM15.4218 5.5H11.4843C11.1736 5.5 10.9218 5.24816 10.9218 4.9375C10.9218 4.62684 11.1736 4.375 11.4843 4.375H15.4218C15.7324 4.375 15.9843 4.62684 15.9843 4.9375C15.9843 5.24816 15.7324 5.5 15.4218 5.5Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M18.8125 13.75H1.1875C1.08395 13.75 1 13.8339 1 13.9375V14.875C1 15.4963 1.50368 16 2.125 16H8.35L6.66325 18.4105C6.54478 18.5804 6.58646 18.8141 6.75634 18.9326C6.81903 18.9763 6.89358 18.9998 6.97 19H13.03C13.2371 18.9998 13.4049 18.8318 13.4047 18.6247C13.4046 18.5478 13.3809 18.4727 13.3367 18.4097L11.65 16H17.875C18.4963 16 19 15.4963 19 14.875V13.9375C19 13.8339 18.9161 13.75 18.8125 13.75Z",
    fill: "none"
  }), _jsx("path", {
    d: "M4.57452 4.37488H5.86302C6.17368 4.37488 6.42552 4.62672 6.42552 4.93738C6.42552 5.24804 6.17368 5.49988 5.86302 5.49988H4.57452C4.26386 5.49988 4.01202 5.24804 4.01202 4.93738C4.01202 4.62672 4.26386 4.37488 4.57452 4.37488ZM8.51202 9.62488H4.57452C4.26386 9.62488 4.01202 9.37304 4.01202 9.06238C4.01202 8.75172 4.26386 8.49988 4.57452 8.49988H8.51202C8.82268 8.49988 9.07452 8.75172 9.07452 9.06238C9.07452 9.37304 8.82268 9.62488 8.51202 9.62488ZM8.73702 6.24988C8.01304 6.28585 7.39698 5.7281 7.36102 5.00412C7.32505 4.28013 7.8828 3.66407 8.60678 3.62811C9.33067 3.59215 9.94668 4.14975 9.98278 4.87363C10.0189 5.59631 9.46336 6.21192 8.74078 6.24988H8.73702ZM11.386 10.3749C10.6621 10.4113 10.0457 9.85386 10.0093 9.1299C9.97293 8.40594 10.5303 7.78956 11.2543 7.75319C11.9782 7.71681 12.5946 8.2742 12.631 8.99817C12.6672 9.72057 12.112 10.3365 11.3898 10.3749H11.386ZM14.137 9.62488C13.8263 9.62488 13.5745 9.37304 13.5745 9.06238C13.5745 8.75172 13.8263 8.49988 14.137 8.49988H15.4255C15.7362 8.49988 15.988 8.75172 15.988 9.06238C15.988 9.37304 15.7362 9.62488 15.4255 9.62488H14.137ZM15.4218 5.49988H11.4843C11.1736 5.49988 10.9218 5.24804 10.9218 4.93738C10.9218 4.62672 11.1736 4.37488 11.4843 4.37488H15.4218C15.7324 4.37488 15.9843 4.62672 15.9843 4.93738C15.9843 5.24804 15.7324 5.49988 15.4218 5.49988Z",
    fill: "white"
  })]
}), 'SpecIcon', 'Navigation');
export default SpecIcon;