/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var EngageIcon = createIconsComponent(_jsxs("svg", {
  width: 32,
  height: 32,
  viewBox: "0 0 32 32",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M16 23.98C20.4072 23.98 23.98 20.4072 23.98 16C23.98 11.5928 20.4072 8.02 16 8.02C11.5928 8.02 8.02 11.5928 8.02 16C8.02 20.4072 11.5928 23.98 16 23.98Z",
    fill: "#F22F46"
  }), _jsx("path", {
    d: "M14.27 27.88C12.2608 27.5865 10.3589 26.7879 8.74246 25.5591C7.12598 24.3303 5.84775 22.7114 5.02744 20.8539C4.20712 18.9965 3.87155 16.9612 4.05214 14.9388C4.23273 12.9163 4.92357 10.9727 6.05999 9.28999C6.18196 9.09913 6.23552 8.87252 6.2119 8.64725C6.18828 8.42198 6.08889 8.21141 5.92999 8.04999L4.49 6.60999C4.38734 6.51286 4.26527 6.43859 4.13181 6.39208C3.99836 6.34557 3.85657 6.32787 3.71578 6.34016C3.57499 6.35244 3.43841 6.39442 3.31503 6.46334C3.19165 6.53226 3.08428 6.62655 3 6.73999C1.05715 9.43744 0.00803418 12.6757 0 16V30.71C0 31.0521 0.13591 31.3802 0.377832 31.6221C0.619754 31.8641 0.94787 32 1.29 32H16C19.3188 32.0065 22.5565 30.975 25.26 29.05C25.3817 28.9661 25.4833 28.8563 25.5574 28.7285C25.6316 28.6006 25.6765 28.458 25.689 28.3107C25.7014 28.1634 25.681 28.0152 25.6293 27.8768C25.5776 27.7383 25.4959 27.6131 25.39 27.51L24 26.07C23.8386 25.9111 23.628 25.8117 23.4027 25.7881C23.1775 25.7645 22.9508 25.818 22.76 25.94C20.2728 27.627 17.2431 28.3193 14.27 27.88Z",
    fill: "#F22F46"
  }), _jsx("path", {
    d: "M17.73 4.12C19.7392 4.41351 21.641 5.21205 23.2575 6.44088C24.874 7.6697 26.1522 9.28862 26.9725 11.1461C27.7928 13.0035 28.1284 15.0387 27.9478 17.0612C27.7672 19.0837 27.0764 21.0273 25.94 22.71C25.8049 22.9054 25.7438 23.1425 25.7676 23.3789C25.7914 23.6153 25.8986 23.8354 26.07 24L27.51 25.44C27.6131 25.5459 27.7383 25.6276 27.8768 25.6793C28.0152 25.731 28.1634 25.7514 28.3107 25.739C28.458 25.7265 28.6007 25.6816 28.7285 25.6074C28.8563 25.5333 28.9661 25.4317 29.05 25.31C30.985 22.5927 32.017 19.3358 32 16V1.29C32 0.94787 31.8641 0.619754 31.6221 0.377832C31.3802 0.13591 31.0521 0 30.71 0H16C12.6757 0.00803418 9.43745 1.05715 6.74 3C6.61831 3.08387 6.51672 3.19366 6.44254 3.32149C6.36837 3.44932 6.32345 3.592 6.31102 3.73927C6.29858 3.88654 6.31895 4.03473 6.37065 4.17319C6.42234 4.31164 6.50409 4.43691 6.61 4.54L8.05 5.92999C8.21141 6.08889 8.42199 6.18828 8.64725 6.2119C8.87252 6.23552 9.09913 6.18196 9.28999 6.05999C11.7638 4.38533 14.7733 3.69358 17.73 4.12Z",
    fill: "#F22F46"
  })]
}), 'EngageIcon', 'Segment');
export default EngageIcon;