/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var ApiHttpIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    opacity: 0.48,
    d: "M12.5 7C11.6715 7 10.5 7 10.5 7H10H2.49985C1.6714 7 1 7 1 7V16.4999C1 17.3277 1.6714 18 2.49985 18H16.5C17.3276 18 17.9999 17.3293 17.9999 16.4999V7H12.5ZM7.8598 14.8296L7.06443 15.625L4.21975 12.7801C3.9268 12.4878 3.9268 12.0126 4.21975 11.7197L7.06443 8.875L7.8598 9.67037L5.28017 12.25L7.8598 14.8296ZM12.9999 14.8751H9.24985V13.7501H12.9999V14.8751Z",
    fill: "none"
  }), _jsx("path", {
    opacity: 0.24,
    d: "M18 5.4017V3.7C18 2.7616 17.2375 2 16.3 2H2.7C1.76245 2 1 2.7616 1 3.7V5.4017H18ZM6.1 2.85C6.5692 2.85 6.95 3.2308 6.95 3.7C6.95 4.1692 6.5692 4.55 6.1 4.55C5.6308 4.55 5.25 4.1692 5.25 3.7C5.25 3.2308 5.6308 2.85 6.1 2.85ZM3.55 2.85C4.0192 2.85 4.4 3.2308 4.4 3.7C4.4 4.1692 4.0192 4.55 3.55 4.55C3.0808 4.55 2.7 4.1692 2.7 3.7C2.7 3.2308 3.0808 2.85 3.55 2.85Z",
    fill: "none"
  }), _jsx("path", {
    d: "M6.95001 3.7001C6.95001 3.2309 6.56921 2.8501 6.10001 2.8501C5.63081 2.8501 5.25001 3.2309 5.25001 3.7001C5.25001 4.1693 5.63081 4.5501 6.10001 4.5501C6.56921 4.5501 6.95001 4.1693 6.95001 3.7001Z",
    fill: "white"
  }), _jsx("path", {
    d: "M4.40001 3.7001C4.40001 3.2309 4.01921 2.8501 3.55001 2.8501C3.08081 2.8501 2.70001 3.2309 2.70001 3.7001C2.70001 4.1693 3.08081 4.5501 3.55001 4.5501C4.01921 4.5501 4.40001 4.1693 4.40001 3.7001Z",
    fill: "white"
  }), _jsx("path", {
    d: "M7.06445 15.625L7.85982 14.8296L5.28019 12.25L7.85982 9.67037L7.06445 8.875L4.21977 11.7197C3.92682 12.0126 3.92682 12.4878 4.21977 12.7801L7.06445 15.625Z",
    fill: "white"
  }), _jsx("path", {
    d: "M9.24987 14.8751H12.9999V13.7501H9.24987V14.8751Z",
    fill: "white"
  })]
}), 'ApiHttpIcon', 'Navigation');
export default ApiHttpIcon;